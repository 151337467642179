import moment from "moment";
import Lottie from "react-lottie";
import { useEffect } from "react";
import React, { useState } from "react";
import Badge from "../components/Badge";
import Button from "../components/Button";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import Spinner from "../components/Spinner";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import { useMediaQuery } from "react-responsive";
import TitleValue from "../components/TitleValue";
import { employeeMediaUrl } from "../utils/config";
import { employees } from "../routes/routesDefined";
import RangePicker from "../components/RangePicker";
import EllipsisText from "../components/EllipsisText";
import StatusFilter from "../components/StatusFilter";
import { useLoggedContext } from "../context/provider";
import ReadMoreModal from "../components/ReadMoreModal";
import ActionLogModal from "../components/ActionLogModal";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import NotifyUserModal from "../components/NotifyUserModal";
import CheckInFormModal from "../components/CheckInFormModal";
import NotificationModal from "../components/NotificationModal";
import ConfirmationModal from "../components/ConfirmationModal";
import CheckInFormFillModal from "../components/CheckInFormFillModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddManuallyModal from "../components/addEmployee/AddManuallyModal";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import MentalHealthAbsenceModal from "../components/MentalHealthAbsenceModal";
import { isUnixTimestamp, toUTCUnixTimestamp } from "../helperFunctions/helper";
import ViewConsultationFormModal from "../components/ViewConsultationFormModal";
import ReadNotificationHistoryModal from "../components/ReadNotificationHistoryModal";
import { ArrowPathIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

// table head for responses
const TABLE_HEAD_RESPONSES = ["Responses", "Reasons", "Date"];

// table head for consultation form
const TABLE_HEAD_CONSULTATION_FORM = ["Forms", "Date", "Action"];

// table head for action logs
const TABLE_HEAD_ACTION_LOGS = ["Date", "Action", "Edit"];

// table head for notfication history
const TABLE_HEAD_NOTIFICATION_HISTORY = ["Title", "Link", "Type", "Description", "Created at"];

const consultationFormOption = [
  { label: "All", value: "all" },
  { label: "Submitted", value: "submitted" },
  { label: "Pending", value: "pending" },
];

// employee analytical  months
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const EmployeeDetail = () => {
  let navigate = useNavigate();

  // react router hook
  const location = useLocation();
  const { id: paramId } = useParams();
  const { employeeDetail } = location.state || {};
  const employeeId = employeeDetail && employeeDetail.id;

  const { btnLoading, setBtnLoading, openSuccessModal, user } = useLoggedContext();

  // useStates
  const [message, setMessage] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [statusLoading, setStatusLoading] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [actionLogListing, setActionLogListing] = useState(null);
  const [checkInFormErrors, setCheckInFormErrors] = useState({});
  const [lineChartLoading, setLineChartLoading] = useState(false);
  const [actionLogLoading, setActionLogLoading] = useState(false);
  const [totalResponsePages, setTotalResponsePages] = useState(0);
  const [totalActiveLogPages, setTotalActiveLogPages] = useState(0);
  const [employeeDetailData, setEmployeeDetailData] = useState(null);

  const [currentResponsePage, setCurrentResponsePage] = useState(null);
  const [employeeReasonsData, setEmployeeReasonseData] = useState(null);
  const [employeeResponseData, setEmployeeResponseData] = useState(null);
  const [currentActiveLogPage, setCurrentActiveLogPage] = useState(null);
  const [actionLogListingCount, setActionLogListingCount] = useState(null);
  const [employeeAnalyticsData, setEmployeeAnalyticsData] = useState(null);
  const [employeeDetailLoading, setEmployeeDetailLoading] = useState(true);
  const [notificationHistoryData, setNotificationHistoryData] = useState(null);
  const [consultationFormLoading, setConsultationFormLoading] = useState(false);
  const [totalConsultationFormPages, setTotalConsultationFormPages] = useState(0);
  const [employeeResponseDataCount, setEmployeeResponseDataCount] = useState(null);
  const [notificationHistoryLoading, setNotificationHistoryLoading] = useState(false);
  const [currentConsultationFormPage, setCurrentConsultationFormPage] = useState(null);
  const [totalNotificationHistoryPages, setTotalNotificationHistoryPages] = useState(0);
  const [notificationHistoryDataCount, setNotificationHistoryDataCount] = useState(null);
  const [employeeConsultationFormData, setEmployeeConsultationFormData] = useState(null);
  const [currentNotificationHistoryPage, setCurrentNotificationHistoryPage] = useState(null);
  const [openNotifyUserModal, setOpenNotifyUserModal] = useState({ open: false, data: null });
  const [employeeConsultationFormDataCount, setEmployeeConsultationFormDataCount] = useState(0);
  const [openStatusChangeModal, setOpenStatusChangeModal] = useState({ open: false, label: "" });
  const [openReadMoreMessageModal, setOpenReadMoreMessageModal] = useState({ open: false, data: null });
  const [openCheckInFormFillModal, setOpenCheckInFormFillModal] = useState({ open: false, data: null });
  const [openConsultationFormModal, setOpenConsultationFormModal] = useState({ open: false, data: null });
  const [openCheckInFormModal, setOpenCheckInFormModal] = useState({ open: false, label: "", data: null });
  const [openActionLogModal, setOpenActionLogModal] = useState({ open: false, data: null, editMode: false });
  const [openQuestioneriesModal, setOpenQuestioneriesModal] = useState({ open: false, label: "", data: null });
  const [openDeleteActionLogModal, setOpenDeleteActionLogModal] = useState({ open: false, label: "", id: "" });
  const [openNotificationMessageModal, setOpenNotificationMessageModal] = useState({ open: false, data: null });
  const [employeeAnalyticDateRange, setEmployeeAnalyticDateRange] = useState({ startDate: null, endDate: null });
  const [selectedConsultationFormOption, setSelectedConsultationFormOption] = useState(consultationFormOption[0]);
  const [employeeFcmToken, setEmployeeFcmToken] = useState("");
  // const [notificationHistoryDateRange, setNotificationHistoryDateRange] = useState({ startDate: null, endDate: null });
  const todayStartDate = moment.utc().startOf("day");
  const todayEndDate = moment.utc().endOf("day");
  const [notificationHistoryDateRange, setNotificationHistoryDateRange] = useState({
    startDate: todayStartDate.unix(),
    endDate: todayEndDate.unix(),
  });

  const [openUpdateEmployeeModal, setOpenUpdateEmployeeModal] = useState({ open: false, data: null, editMode: false });
  const [openMentalHealthAbsenceModal, setOpenMentalHealthAbsenceModal] = useState({ open: false, label: "", data: null });
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState({ open: false, label: "", innerLabel: "", data: null });
  const [openReadMoreNotificationHistoryModal, setOpenReadMoreNotificationHistoryModal] = useState({ open: false, data: null });

  // custom tooltip for employee reasons graph
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const name = payload[0].name;
      const value = payload[0].value;

      let color;

      const data2Item = data2.find((item) => item.name === name);
      color = data2Item ? data2Item.color : "#000000";

      return (
        <div className="relative rounded-20 bg-white p-4 shadow-card">
          <div className="h-6 w-6 rounded-full ">
            <img
              src={
                name === "Poor Sleep "
                  ? images.SleepIcon
                  : name === "Home Life Difficulties"
                    ? images.HomeLifeIcon
                    : name === "Relationship Problems"
                      ? images.RelationIcon
                      : name === "Unwell"
                        ? images.UnWellIcon
                        : name === "Bereavement"
                          ? images.BereavementIcon
                          : name === "Work Related Stress"
                            ? images.StressIcon
                            : name === "Mental Health Rest Day"
                              ? images.MentleHealth
                              : images.placeholder
              }
              alt="reason"
              className="h-full w-full rounded-full object-contain"
            />
          </div>
          <p className="label" style={{ color }}>{`${name} : ${value}%`}</p>
        </div>
      );
    }
    return null;
  };

  // employees reasons data handling
  const poorSleep = employeeReasonsData ? parseFloat(employeeReasonsData?.poor_sleep_count_percentage) : 0;
  const Unwell = employeeReasonsData ? parseFloat(employeeReasonsData?.unwell_count_percentage) : 0;
  const relationshipProblems = employeeReasonsData ? parseFloat(employeeReasonsData?.relationship_problem_count_percentage) : 0;
  const workRelatedStres = employeeReasonsData ? parseFloat(employeeReasonsData?.work_related_stress_count_percentage) : 0;
  const homeLifeDifficulties = employeeReasonsData ? parseFloat(employeeReasonsData?.home_life_diffuculties_count_percentage) : 0;
  const bereavement = employeeReasonsData ? parseFloat(employeeReasonsData?.bereavement_count_percentage) : 0;
  const mentleHealthAbsense = employeeReasonsData ? parseFloat(employeeReasonsData?.mars_absence_count_percentage) : 0;

  const data2 = [
    {
      name: "Poor Sleep ",
      value:
        poorSleep !== 0
          ? poorSleep
          : poorSleep == 0 &&
              Unwell == 0 &&
              relationshipProblems == 0 &&
              workRelatedStres == 0 &&
              homeLifeDifficulties == 0 &&
              bereavement == 0 &&
              mentleHealthAbsense == 0
            ? 100
            : 0,
      color: poorSleep !== 0 ? "#264254" : "#d1d5db",
    },
    {
      name: "Unwell",
      value: Unwell !== 0 ? Unwell : 0,
      color: Unwell !== 0 ? "#FEDEBC" : "#d1d5db",
    },
    {
      name: "Relationship Problems",
      value: relationshipProblems !== 0 ? relationshipProblems : 0,
      color: relationshipProblems !== 0 ? "#FCEBB0" : "#d1d5db",
    },
    {
      name: "Work Related Stress",
      value: workRelatedStres !== 0 ? workRelatedStres : 0,
      color: workRelatedStres !== 0 ? "#F0D2C9" : "#d1d5db",
    },
    {
      name: "Home Life Difficulties",
      value: homeLifeDifficulties !== 0 ? homeLifeDifficulties : 0,
      color: homeLifeDifficulties !== 0 ? "#FFBF9D" : "#d1d5db",
    },
    {
      name: "Bereavement",
      value: bereavement !== 0 ? bereavement : 0,
      color: bereavement !== 0 ? "#B6E7CF" : "#d1d5db",
    },
  ];

  // Individual employees analytics data handling
  const categories = [
    { id: "Great", classNameColor: "text-[#FFAE9B]", label: "Great", logo: images.GreatIcon, count: employeeReasonsData?.great_count },
    { id: "Okay", classNameColor: "text-[#FFBC96]", label: "Okay", logo: images.OkayIcon, count: employeeReasonsData?.okay_count },
    { id: "Meh", classNameColor: "text-[#FFDCB8]", label: "Meh", logo: images.MehIcon, count: employeeReasonsData?.meh_count },
    { id: "Low", classNameColor: "text-[#AAE9CD]", label: "Low", logo: images.LowIcon, count: employeeReasonsData?.low_count },
    {
      id: "Struggling",
      classNameColor: "text-[#264254]",
      label: "Struggling",
      logo: images.StugglingIcon,
      count: employeeReasonsData?.struggling_count,
    },
  ];

  const dataline = months.map((month) => ({
    name: month,
    Great: 0,
    Okay: 0,
    Meh: 0,
    Low: 0,
    Struggling: 0,
  }));

  if (activeCategory) {
    const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
    filteredData?.forEach((data) => {
      const date = new Date(data.created_date_unix * 1000);
      const monthIndex = date.getMonth();
      const response = data.response;
      dataline[monthIndex][response] += 1;
    });
  } else {
    employeeAnalyticsData?.forEach((data) => {
      const date = new Date(data.created_date_unix * 1000);
      const monthIndex = date.getMonth();
      const response = data.response;
      dataline[monthIndex][response] += 1;
    });
  }

  const formatYAxis = (value) => `${value}`;
  const formatTooltip = (value) => {
    return value.toFixed(2);
  };

  // handle employee analytical categories
  const handleCategoryClick = (label) => {
    if (activeCategory === label) {
      setActiveCategory("");
    } else {
      setActiveCategory(label);
    }
  };

  // handle reset graph data
  const handleResetClick = () => {
    setActiveCategory("");
    getEmployeeStatsById();
  };

  // handle Check in form date
  const handleDateChange = (date) => {
    // const unixTimestamp = date.getTime();
    setCheckInDate(date);
    setMessage("");
  };

  // handle Check in form date
  // const handleDateChange = (date) => {
  //   // Convert local date to UTC date
  //   const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  //   // Convert UTC date to Unix time
  //   const unixTimestamp = Math.floor(utcDate.getTime() / 1000);

  //   // Set the Unix timestamp
  //   setCheckInDate(unixTimestamp);
  //   setMessage("");
  // };

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(employeeConsultationFormData);

  const { items: itemsResponses, requestSort: requestSortResponses, sortConfig: sortConfigResponses } = useSortableData(employeeResponseData);

  const { items: itemsActionLogs, requestSort: requestSortActionLogs, sortConfig: sortConfigActionLogs } = useSortableData(actionLogListing);

  const {
    items: itemsNotificationHistory,
    requestSort: requestSortNotificationHistory,
    sortConfig: sortConfigNotificationHistory,
  } = useSortableData(notificationHistoryData);

  // Media Queries

  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });

  const itemsPerPage = isLargeScreenLaptop ? 7 : isLaptopMedium ? 4 : isLaptop ? 4 : 4;
  const shortDescription = 15;

  // All Responses Listing pagination
  const handleResponsePageChange = ({ selected }) => {
    setCurrentResponsePage(selected + 1);
  };

  // All Notification History pagination
  const handleNotificationHistoryPageChange = ({ selected }) => {
    setCurrentNotificationHistoryPage(selected + 1);
  };

  // All Active Log Listing pagination
  const handleActiveLogPageChange = ({ selected }) => {
    setCurrentActiveLogPage(selected + 1);
  };

  // All Consultation Form Listing pagination
  const handleConsultationFormPageChange = ({ selected }) => {
    setCurrentConsultationFormPage(selected + 1);
  };

  // get employee detail
  const getEmployeeDetail = () => {
    ApiServices.employees
      .getEmployeeDetailById(employeeId ? employeeId : paramId)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setEmployeeDetailData(data?.data);
          setEmployeeFcmToken(data?.data?.employee[0]?.fcm);
          setEmployeeDetailLoading(false);
        }
      })
      .catch((err) => {
        setEmployeeDetailLoading(false);
      });
  };

  // get employee responses data
  const getEmployeeResponsesListing = () => {
    setResponseLoading(true);
    let payload = {
      page: currentResponsePage,
      records_no: itemsPerPage,
    };
    ApiServices.employees
      .getEmployeeResponsesId(employeeId ? employeeId : paramId, payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setResponseLoading(false);
          setEmployeeResponseData(data?.data?.data);
          setEmployeeResponseDataCount(data?.data?.total);
          setCurrentResponsePage(data?.data?.current_page);
          setTotalResponsePages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {});
  };

  // get employee action log by id
  const getActionLogByEmployeeID = () => {
    setActionLogLoading(true);
    let payload = {
      page: currentActiveLogPage,
      records_no: itemsPerPage,
    };
    ApiServices.employees
      .getEmployeeActionLogById(employeeId ? employeeId : paramId, payload)
      .then((res) => {
        const { data, message } = res;

        //

        if (res.status === 200) {
          setActionLogListing(res?.data?.data);
          setActionLogListingCount(res?.data?.total);
          setCurrentActiveLogPage(res?.data?.current_page);
          setTotalActiveLogPages(Math.ceil(res?.data?.total / res?.data?.per_page));
          setActionLogLoading(false);
        }
      })
      .catch((err) => {});
  };

  // get Notifications listing
  const getNotificationsHistory = async () => {
    setNotificationHistoryLoading(true);
    let payload = {
      user_id: employeeId ? employeeId : paramId,
      page: currentNotificationHistoryPage,
      records_no: itemsPerPage,
      start_date: notificationHistoryDateRange.startDate ? notificationHistoryDateRange.startDate : null,
      end_date: notificationHistoryDateRange.endDate ? notificationHistoryDateRange.endDate : null,
    };

    ApiServices.broadcastNotification
      .getBroadcastNotifications(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setNotificationHistoryLoading(false);
          setNotificationHistoryDataCount(data?.data?.total);
          setNotificationHistoryData(data?.data?.data);
          setCurrentNotificationHistoryPage(data?.data?.current_page);
          setTotalNotificationHistoryPages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {});
  };

  // get employee consultation form
  const getEmployeeConsultationForm = () => {
    setConsultationFormLoading(true);

    let payload = {};
    if (selectedConsultationFormOption?.value === "all") {
      payload = {
        page: currentConsultationFormPage,
        records_no: itemsPerPage,
      };
    } else {
      payload = {
        status: selectedConsultationFormOption?.value,
        page: currentConsultationFormPage,
        records_no: itemsPerPage,
      };
    }
    ApiServices.employees
      .getEmployeeConsultaionFormById(employeeId ? employeeId : paramId, payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setConsultationFormLoading(false);
          setEmployeeConsultationFormData(data?.data?.data);
          setEmployeeConsultationFormDataCount(data?.data?.total);
          setCurrentConsultationFormPage(data?.data?.current_page);
          setTotalConsultationFormPages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {});
  };

  // send questionnire form
  const sendQuestionnaireFormById = () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("employee_id", employeeId ? employeeId : paramId);

    ApiServices.employees
      .sendQuestionnaireForm(formData)
      .then((res) => {
        if (res?.data?.code === 200) {
          setBtnLoading(false);
          openSuccessModal({
            title: "Success!",
            message: "Questionnaire form has been Sent!",
            onPress: (close) => {
              close();
            },
          });
          setOpenQuestioneriesModal({ open: false, label: "" });
        }
      })
      .catch(({ response }) => {});
  };

  // request for well being check in form
  const sendWellBeingCheckInFormByID = () => {
    if (!checkInDate) {
      setMessage("Date is required");
      return;
    } else {
      setBtnLoading(true);
      const messageData = new FormData();
      messageData.append("employee_id", employeeId ? employeeId : paramId);
      messageData.append("submitted_date", moment(checkInDate).format("D MMM YYYY h:mm A"));
      ApiServices.employees
        .requestWellBeingCheckInForm(messageData)
        .then((res) => {
          //
          if (res?.data?.code === 200) {
            setBtnLoading(false);
            getEmployeeConsultationForm();
            openSuccessModal({
              title: "Success!",
              message: "Well Being Check In form has been Sent!",
              onPress: (close) => {
                close();
                getEmployeeConsultationForm();
              },
            });
            setCheckInDate(null);
            setOpenCheckInFormModal({ open: false, label: "" });
          }
          setBtnLoading(false);
        })
        .catch(({ response }) => {
          setBtnLoading(false);
        });
    }
  };

  // get employee stats by id
  const getEmployeeStatsById = () => {
    setLineChartLoading(true);
    let payload = {
      start_date: employeeAnalyticDateRange.startDate,
      end_date: employeeAnalyticDateRange.endDate,
    };
    ApiServices.employees
      .getEmployeeStatsByID(employeeId ? employeeId : paramId, payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setLineChartLoading(false);
          setEmployeeReasonseData(data?.data);
          setEmployeeAnalyticsData(data?.data?.users_response);
        }
        setLineChartLoading(false);
      })
      .catch((err) => {
        setLineChartLoading(false);
      });
  };

  // delete employee action log
  const deleteActionLog = () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("id", openDeleteActionLogModal.id);
    ApiServices.employees
      .deleteActionLog(formData)
      .then((res) => {
        if (res?.data?.code === 200) {
          setBtnLoading(false);
          openSuccessModal({
            title: "Success!",
            message: "Action Log has been Deleted!",
            onPress: (close) => {
              close();
              getActionLogByEmployeeID();
            },
          });
          setBtnLoading(false);
        }
      })
      .catch(({ response }) => {});
  };

  // Active Deactive
  const handleActiveDeactiveEmployee = () => {
    setStatusLoading(true);
    // let employeeMessage = openStatusChangeModal.label === "activate" ? "Employee has been Activated" : "Employee has been Deactivated";
    const formData = new FormData();
    formData.append("id", employeeId ? employeeId : paramId);
    ApiServices.employees
      .activeDeactiveEmployee(formData)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          getEmployeeDetail();
          setTimeout(() => {
            setStatusLoading(false);
          }, 600);
          // setOpenStatusChangeModal({ open: false, label: "" });
          // openSuccessModal({
          //   title: "Success!",
          //   message: employeeMessage,
          //   onPress: (close) => {
          //     close();
          //     getEmployeeDetail();
          //   },
          // });
        }
      })
      .catch((err) => {});
  };

  // Delete Account
  const handleDeleteAccount = () => {
    setBtnLoading(true);
    let payload = {
      id: employeeId ? employeeId : paramId,
    };
    ApiServices.employees
      .deleteEmployee(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setBtnLoading(false);

          setOpenStatusChangeModal({ open: false, label: "" });
          openSuccessModal({
            title: " Account has been deleted Successfully!",
            message: message,
            onPress: (close) => {
              close();
              navigate("/" + employees, { replace: false });
            },
          });
        }
      })
      .catch((err) => {});
  };

  // useEffects
  useEffect(() => {
    getEmployeeDetail();
  }, []);

  useEffect(() => {
    getEmployeeConsultationForm();
  }, [selectedConsultationFormOption, currentConsultationFormPage]);

  useEffect(() => {
    getEmployeeResponsesListing();
  }, [currentResponsePage]);

  useEffect(() => {
    getEmployeeStatsById();
  }, [employeeAnalyticDateRange]);

  useEffect(() => {
    getActionLogByEmployeeID();
  }, [currentActiveLogPage]);

  useEffect(() => {
    getNotificationsHistory();
  }, [currentNotificationHistoryPage, notificationHistoryDateRange]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Short property for profile data
  const employeeInfo = employeeDetailData && employeeDetailData?.employee && employeeDetailData?.employee[0] && employeeDetailData?.employee[0];

  return (
    <>
      <div className="grid min-h-[83vh]  grid-cols-12 gap-4">
        <div className="col-span-12 space-y-4 xl:col-span-4">
          <div className="card flex flex-col justify-between gap-y-8">
            <div className="space-y-8 pt-6">
              <div className="flex items-center justify-between">
                <div className="-ml-6 rounded-r-full bg-[#DDEFE7] py-2 px-6 2xl:px-10">
                  <h2 className="text-sm font-medium text-secondary 2xl:text-lg">Employee ID: #{employeeInfo?.emp_id}</h2>
                </div>
                {employeeDetailLoading ? (
                  <Skeleton count={1} height={30} width={100} />
                ) : (
                  <button
                    type="button"
                    onClick={() => handleActiveDeactiveEmployee(employeeDetailData)}
                    className={`cursor-pointer text-xs font-normal ${employeeDetailData?.status === 0 ? "text-accent" : " text-red-500"} underline underline-offset-4 3xl:text-sm`}
                  >
                    {statusLoading ? <Spinner /> : employeeDetailData && employeeDetailData.status === 0 ? "Unblock" : "Block"}
                  </button>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-4">
                  {employeeDetailLoading ? (
                    <Skeleton count={1} className="!h-20 !w-20 !rounded-full 2xl:!h-28 2xl:!w-28" />
                  ) : (
                    <div className="h-20 w-20 rounded-full 2xl:h-28 2xl:w-28">
                      <img
                        src={employeeInfo?.profile_image_url ? employeeMediaUrl + employeeInfo?.profile_image_url : images.placeholder}
                        className="h-full w-full rounded-full object-cover"
                        alt="icon"
                      />
                    </div>
                  )}
                  {employeeDetailLoading ? (
                    <Skeleton count={1} height={80} width={200} />
                  ) : (
                    <div className="space-y-1">
                      <div>
                        <h2 className="text-lg font-medium text-black">{employeeInfo?.name}</h2>
                        <h3 className="break-all text-sm text-secondary-color">{employeeDetailData?.email}</h3>
                      </div>
                      {employeeFcmToken === null || employeeFcmToken === "" || employeeFcmToken === undefined ? (
                        <Badge status="Notifications Disabled" />
                      ) : (
                        <Badge status="Notifications Enabled" />
                      )}
                    </div>
                  )}
                </div>
                <button
                  onClick={() => setOpenUpdateEmployeeModal({ open: true, data: employeeDetailData, editMode: true })}
                  className="text-sm font-medium text-secondary underline underline-offset-2 2xl:text-lg"
                  type="button"
                >
                  Edit
                </button>
              </div>
              <div className="space-y-4">
                <div className="grid grid-cols-12 gap-y-4 rounded-lg bg-detailProfileColor py-6 pl-6">
                  <div className="col-span-12">
                    <h2 className="text-sm font-semibold text-black 2xl:text-base">Personal Information</h2>
                  </div>

                  <div className="col-span-6">
                    <TitleValue title="Phone" value={employeeInfo?.phone_number || "N/A"} />
                  </div>
                  <div className="col-span-6">
                    <TitleValue
                      title="Date of Birth"
                      value={employeeInfo?.date_of_birth === null ? "N/A" : moment.unix(employeeInfo?.date_of_birth).format("MMMM DD, YYYY")}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-y-4 rounded-lg bg-detailProfileColor py-6 pl-6">
                  <div className="col-span-12">
                    <h2 className="text-sm font-semibold text-black 2xl:text-base">Work Information</h2>
                  </div>
                  <div className="col-span-6">
                    <TitleValue title="Job Title" value={employeeInfo?.job_title || "N/A"} />
                  </div>
                  {/* <div className="col-span-6">
                    <TitleValue title="Room" value={employeeInfo?.room_name || "N/A"} />
                  </div> */}
                  {/* <div className="col-span-6">
                    <TitleValue title="Key Children" value={employeeInfo?.key_children || "N/A"} />
                  </div> */}
                  <div className="col-span-6">
                    <TitleValue
                      title="Joining Date"
                      value={employeeInfo?.unix_joining_date === null ? "N/A" : moment.unix(employeeInfo?.unix_joining_date).format("MMMM DD, YYYY")}
                    />
                  </div>
                  <div className="col-span-6 pr-1">
                    <TitleValue title="Education" value={employeeInfo?.qualification || "N/A"} />
                  </div>
                  <div className="col-span-6">
                    <TitleValue title="Work days per week" value={employeeInfo?.shift_time || "N/A"} />
                  </div>
                  <div className="col-span-6">
                    <TitleValue title="Site" value={employeeInfo?.campus?.name || "N/A"} />
                  </div>
                  <div className="col-span-6">
                    <TitleValue title="Department" value={employeeInfo?.department?.name || "N/A"} />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-y-4 rounded-lg bg-detailProfileColor py-6 pl-6">
                  <div className="col-span-12">
                    <h2 className="text-sm font-semibold text-black 2xl:text-base">Well-being Information</h2>
                  </div>
                  <div className="col-span-6 ">
                    <TitleValue title="Existing Mental Health Conditions" value={employeeInfo?.mental_health || "N/A"} />
                  </div>
                  <div className="col-span-6 pr-1">
                    <TitleValue title="Medications" value={employeeInfo?.medication || "N/A"} />
                  </div>
                  <div className="col-span-6">
                    <TitleValue title="Emotional Triggers" value={employeeInfo?.emotional_trigger || "N/A"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <Button
                title="Send Message"
                buttonColor="bg-secondary/10 text-secondary"
                className="w-full"
                onClick={() => setOpenNotifyUserModal({ open: true, data: employeeDetailData })}
              />
              <Button
                title="Mark as Mental Health Rest Day"
                buttonColor="border border-red-500 hover:bg-red-500 hover:text-white text-red-500"
                className="w-full !px-2"
                onClick={() => setOpenMentalHealthAbsenceModal({ open: true, label: "Mark as Mental Health Rest Day" })}
              />
              <Button
                title="Request for Well Being Check-in"
                buttonColor="border border-secondary hover:bg-secondary hover:text-white text-secondary"
                className="w-full !px-2"
                onClick={() => setOpenCheckInFormModal({ open: true, label: "Check in Form", data: employeeDetailData })}
              />
              <Button
                title="Request for Well Being Questionnaire"
                className="w-full !px-3 hover:shadow-2xl"
                onClick={() => setOpenQuestioneriesModal({ open: true, label: "send the Questionnaire Form" })}
              />
            </div>

            <div className="mx-auto pt-24 3xl:pt-16">
              <button
                className="text-base font-medium text-red-500 underline underline-offset-2"
                type="button"
                onClick={() =>
                  setOpenDeleteAccountModal({
                    open: true,
                    label: "Delete",
                    innerLabel: "Entire account and data will be deleted",
                    data: "Shahab Ali",
                  })
                }
              >
                Delete Account?
              </button>
            </div>
          </div>

          {/* Forms */}
          {(user?.user?.permissions?.includes("employee-view-submitted-forms") || user?.user?.role === "setting_user") && (
            <div className="card col-span-4">
              <div className="flex items-center justify-between">
                <div className="flex gap-x-2">
                  <Heading heading="Forms" />
                  <h2 className="pt-1.5 text-sm text-grayColor300"> ({employeeConsultationFormDataCount})</h2>
                  <ArrowPathIcon
                    onClick={() => {
                      getEmployeeConsultationForm();
                    }}
                    className="mt-1.5 h-5 w-5 cursor-pointer text-grayColor300"
                  />
                </div>
                <StatusFilter
                  maxWidth="w-32 pb-2"
                  className="rounded-md bg-secondary text-white"
                  selectedLabelColor="text-secondary font-medium"
                  selected={selectedConsultationFormOption}
                  setSelected={setSelectedConsultationFormOption}
                  options={consultationFormOption}
                />
              </div>
              <div className="-mx-6 mb-12 h-88">
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD_CONSULTATION_FORM?.map((head) => (
                        <th
                          key={head}
                          className="border-b border-gray-100 bg-white p-4 first:pl-6 "
                          onClick={() => {
                            let sortKey;
                            if (head === "Forms") {
                              sortKey = "title";
                            } else if (head === "Date") {
                              sortKey = "submit_at";
                            } else {
                              sortKey = head.toLowerCase();
                            }
                            requestSort(sortKey);
                          }}
                        >
                          <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                            {head}
                            {head !== "Action" && (
                              <>
                                {sortConfig.key === (head === "Forms" ? "title" : head === "Date" ? "submit_at" : head.toLowerCase()) &&
                                sortConfig.direction === "asc" ? (
                                  <ChevronUpIcon className="inline-block h-4 w-3.5" />
                                ) : (
                                  <ChevronDownIcon className="inline-block h-4 w-3.5" />
                                )}
                              </>
                            )}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {consultationFormLoading ? (
                      <tr>
                        <td colSpan="6">
                          <Skeleton count={5} height={50} />
                        </td>
                      </tr>
                    ) : items?.length > 0 ? (
                      items?.map((item, index) => (
                        <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                          <td className="py-3 pl-6 3xl:pr-4">
                            <div className="flex items-center gap-x-1">
                              <img src={images.PDF} alt="logo" className="h-6 w-6" />
                              <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">{item?.title}</p>
                            </div>
                          </td>
                          <td className="py-3 pl-4 3xl:px-4">
                            <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                              {isUnixTimestamp(item?.submit_at) ? (
                                <p className="text-10 font-normal text-primary-color-200 2xl:text-xs">
                                  {moment.unix(Number(item.submit_at)).format("D MMM YYYY h:mm A")}
                                </p>
                              ) : (
                                <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">{item.submit_at}</p>
                              )}
                            </p>
                          </td>
                          <td className="py-3 pl-4 3xl:px-4">
                            {item?.is_pending === 1 ? (
                              <button
                                className="cursor-pointer rounded-md bg-secondary px-4 py-1.5 text-10 text-white 2xl:text-xs"
                                type="button"
                                onClick={() => setOpenCheckInFormFillModal({ open: true, data: item?.id })}
                              >
                                Fill
                              </button>
                            ) : (
                              <p
                                className="cursor-pointer pl-2 text-10 font-normal text-secondary underline 2xl:text-xs"
                                onClick={() => setOpenConsultationFormModal({ open: true, data: item })}
                              >
                                View
                              </p>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="h-[500px]">
                        <td colSpan="6">
                          <Lottie options={emptyFolderAnimation} width={200} height={200} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="absolute bottom-4">
                <ReactPaginate
                  breakLabel="..."
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  activeClassName="active"
                  nextClassName="item next"
                  renderOnZeroPageCount={null}
                  breakClassName="item break-me "
                  containerClassName="pagination"
                  previousClassName="item previous"
                  pageCount={totalConsultationFormPages}
                  pageClassName="item pagination-page"
                  forcePage={currentConsultationFormPage - 1}
                  onPageChange={handleConsultationFormPageChange}
                  nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                  previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                />
              </div>
            </div>
          )}
          {/* End Forms */}
        </div>

        <div className="col-span-12 space-y-4 xl:col-span-8">
          <div className="card space-y-6">
            <div className="flex justify-between">
              <div className="flex items-center gap-x-4">
                <Heading
                  heading="Individual Employee Analytics"
                  icon
                  handleRefresh={() => {
                    handleResetClick();
                  }}
                />
              </div>

              <RangePicker setRangePicker={setEmployeeAnalyticDateRange} width="xl:w-80" />
            </div>

            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 space-y-14 xl:col-span-8">
                <div className="grid grid-cols-5">
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      onClick={() => handleCategoryClick(category.label)}
                      className={`cursor-pointer space-y-2 ${activeCategory && activeCategory !== category.label ? "opacity-50" : ""}`}
                    >
                      <div className="flex items-center gap-x-1">
                        <img src={category?.logo} alt="logo" />
                        <h2 className="text-xl font-medium text-secondary">{category?.count}</h2>
                      </div>
                      <div className="space-y-1">
                        <h2 className="text-[8px] font-medium text-grayColor300 2xl:text-10">Times Feeling</h2>
                        <h2 className={`${category?.classNameColor} text-10 font-medium uppercase 2xl:text-xs`}>{category?.label}</h2>
                      </div>
                    </div>
                  ))}
                </div>
                {lineChartLoading ? (
                  <div className="grid grid-cols-1 gap-4">
                    {[...Array(1)].map((_, index) => (
                      <Skeleton key={index} count={1} height={350} borderRadius={16} />
                    ))}
                  </div>
                ) : (
                  <>
                    <div className="-ml-16">
                      <ResponsiveContainer width="100%" height={470}>
                        <LineChart
                          width={500}
                          height={300}
                          data={dataline}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" vertical={false} />
                          <XAxis dataKey="name" tickLine={false} />
                          <YAxis tickLine={false} tickFormatter={formatYAxis} />
                          <Tooltip formatter={formatTooltip} />
                          {/* <Legend /> */}
                          <Line type="monotone" dataKey="Great" strokeWidth={2} dot={false} stroke="#FFAE9B" activeDot={{ r: 6 }} />
                          <Line type="monotone" dataKey="Okay" strokeWidth={2} dot={false} stroke="#FFBC96" activeDot={{ r: 6 }} />
                          <Line type="monotone" dataKey="Meh" strokeWidth={2} dot={false} stroke="#FFDCB8" activeDot={{ r: 6 }} />
                          <Line type="monotone" dataKey="Low" strokeWidth={2} dot={false} stroke="#AAE9CD" activeDot={{ r: 6 }} />
                          <Line type="monotone" dataKey="Struggling" strokeWidth={2} dot={false} stroke="#264254" activeDot={{ r: 6 }} />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </>
                )}
              </div>
              <div className="col-span-12 rounded-md bg-detailProfileColor p-4 xl:col-span-4">
                <Heading heading="Reasons" subheading="Meh, Low, Struggling" />
                <div className="flex flex-col items-center gap-y-3 ">
                  <div>
                    <ResponsiveContainer width={240} height={220}>
                      <PieChart
                        margin={{
                          top: 5,
                          right: 5,
                          bottom: 5,
                          left: 0,
                        }}
                      >
                        <Pie
                          margin={{
                            top: 5,
                            right: 5,
                            bottom: 5,
                            left: 0,
                          }}
                          data={data2}
                          height={220}
                          width={300}
                          cx="50%"
                          cy="50%"
                          // innerRadius={40}
                          outerRadius={90}
                          fill="#d6d6d6"
                          dataKey="value"
                        >
                          {data2.map((entry, index) => (
                            <Cell key={`cell-${index}`} stroke="none" className="border-none outline-none" fill={entry.color} />
                          ))}
                        </Pie>

                        {(poorSleep !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (Unwell !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (relationshipProblems !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (workRelatedStres !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (homeLifeDifficulties !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (bereavement !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (mentleHealthAbsense !== 0 && <Tooltip content={<CustomTooltip />} />)}
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="space-y-6">
                    <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                      <div className="col-span-6 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.SleepIcon} alt="poor sleep" className="h-8 w-8" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.poor_sleep_count_percentage}</h2>
                            <p className="text-10 font-medium text-grayColor300">Poor Sleep</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.HomeLifeIcon} alt="Home Life Difficulties" className="h-8 w-8" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.home_life_diffuculties_count_percentage}</h2>
                            <p className="text-10 font-medium text-grayColor300">Home Life Difficulties</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                      <div className="col-span-6 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.RelationIcon} alt="Relationship Problems" className="h-8 w-8" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.relationship_problem_count_percentage}</h2>
                            <p className="text-10 font-medium text-grayColor300">Relationship Problems</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.UnWellIcon} alt="Unwell" className="h-8 w-8" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.unwell_count_percentage}</h2>
                            <p className="text-10 font-medium text-grayColor300">Unwell</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                      <div className="col-span-6 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.BereavementIcon} alt="Bereavement" className="h-8 w-8" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.bereavement_count_percentage}</h2>
                            <p className="text-10 font-medium text-grayColor300">Bereavement</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.StressIcon} alt="Work Related Stress" className="h-8 w-8" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.work_related_stress_count_percentage}</h2>
                            <p className="text-10 font-medium text-grayColor300">Work Related Stress</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                      <div className="col-span-12 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.MentleHealth} alt="Mental Health Rest Day" className="h-8 w-8" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.mars_absence_count_percentage}</h2>
                            <p className="text-10 font-medium text-grayColor300">Mental Health Rest Day</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-8 gap-4">
            <div className="card col-span-12 xl:col-span-4">
              <div className="flex gap-x-2">
                <Heading heading="Responses" />
                <h2 className="pt-1.5 text-sm text-grayColor300"> ({employeeResponseDataCount})</h2>
                <ArrowPathIcon
                  onClick={() => {
                    getEmployeeResponsesListing();
                  }}
                  className="mt-1 h-5 w-5 cursor-pointer text-grayColor300"
                />
              </div>
              <div className="-mx-6 mb-12 h-[21rem] 3xl:h-[24rem]">
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD_RESPONSES?.map((head) => (
                        <th
                          key={head}
                          className="border-b border-gray-100 bg-white p-4 first:pl-6"
                          onClick={() => {
                            let sortKey;
                            if (head === "Responses") {
                              sortKey = "response";
                            } else if (head === "Reasons") {
                              sortKey = "responses.reason";
                            } else if (head === "Attendance") {
                              sortKey = "attendance";
                            } else if (head === "Date") {
                              sortKey = "created_date_unix";
                            } else {
                              sortKey = head.toLowerCase();
                            }
                            requestSortResponses(sortKey);
                            //
                          }}
                        >
                          <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                            {head}
                            {sortConfigResponses.key ===
                              (head === "Responses"
                                ? "response"
                                : head === "Reasons"
                                  ? "responses.reason"
                                  : head === "Date"
                                    ? "created_date_unix"
                                    : head.toLowerCase()) && sortConfigResponses.direction === "asc" ? (
                              <ChevronUpIcon className="inline-block h-4 w-3.5" />
                            ) : (
                              <ChevronDownIcon className="inline-block h-4 w-3.5" />
                            )}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {responseLoading ? (
                      <tr>
                        <td colSpan="6">
                          <Skeleton count={itemsPerPage} height={50} />
                        </td>
                      </tr>
                    ) : itemsResponses?.length > 0 ? (
                      itemsResponses?.map((item, index) => (
                        <tr
                          key={item?.id}
                          className={`transition-all duration-300 ${item.mark_absence === 1 ? "bg-red-50" : ""}`}
                          onClick={() => setOpenNotificationMessageModal({ open: true, data: item })}
                        >
                          <td className="py-3 pl-6 3xl:pr-4">
                            <div className="flex items-center gap-x-1">
                              {item?.response == null || item?.response === undefined ? (
                                ""
                              ) : (
                                <img
                                  src={
                                    item?.response === "Great"
                                      ? images.GreatIcon
                                      : item?.response === "Okay"
                                        ? images.OkayIcon
                                        : item?.response === "Meh"
                                          ? images.MehIcon
                                          : item?.response === "Low"
                                            ? images.LowIcon
                                            : item?.response === "Struggling"
                                              ? images.StugglingIcon
                                              : ""
                                  }
                                  alt="logo"
                                  className="h-6 w-6"
                                />
                              )}

                              <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                                {item?.response == null || item?.response === undefined ? "No Response" : item?.response}
                                {/* {item?.response || "N/A"} */}
                              </p>
                            </div>
                          </td>
                          <td className="py-3 pl-2 3xl:pl-4">
                            <div className="flex cursor-pointer items-center gap-x-1">
                              {item?.reason == null ||
                              item?.reason === undefined ||
                              item?.response?.toLowerCase() === "great" ||
                              item?.response?.toLowerCase() === "okay" ? (
                                ""
                              ) : (
                                <img
                                  src={
                                    item?.reason === "Relationship Problems"
                                      ? images.RelationIcon
                                      : item?.reason === "Poor Sleep"
                                        ? images.SleepIcon
                                        : item?.reason === "Unwell"
                                          ? images.UnWellIcon
                                          : item?.reason === "Work Stress"
                                            ? images.StressIcon
                                            : item?.reason === "Bereavement"
                                              ? images.BereavementIcon
                                              : item?.reason === "Home Life Difficulties"
                                                ? images.HomeLifeIcon
                                                : item?.reason === "Mental Health Rest Day"
                                                  ? images.MentleHealth
                                                  : ""
                                  }
                                  alt="logo"
                                  className="h-6 w-6"
                                />
                              )}
                              <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                                {/* {item?.reason || "N/A"} */}
                                {item?.reason == null ||
                                item?.reason === undefined ||
                                item?.response?.toLowerCase() === "great" ||
                                item?.response?.toLowerCase() === "okay"
                                  ? "No Reason"
                                  : item?.reason}
                              </p>
                            </div>
                          </td>
                          <td className="flex items-center gap-x-2 py-3 pl-2 3xl:px-4">
                            <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                              {moment.unix(item?.created_date_unix).format("D MMM YYYY")}
                            </p>
                            <p>
                              {item?.is_admin === 1 && (
                                <p className="rounded-full bg-responseFromBadgeColor px-4 text-10 font-medium text-secondary xl:-ml-1 3xl:-mr-6 3xl:ml-2 ">
                                  Admin
                                </p>
                              )}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className=" h-[250px]">
                        <td colSpan="6">
                          <Lottie options={emptyFolderAnimation} width={200} height={200} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="absolute bottom-4">
                <ReactPaginate
                  breakLabel="..."
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  activeClassName="active"
                  nextClassName="item next"
                  renderOnZeroPageCount={null}
                  breakClassName="item break-me "
                  containerClassName="pagination"
                  previousClassName="item previous"
                  pageCount={totalResponsePages}
                  pageClassName="item pagination-page"
                  forcePage={currentResponsePage - 1}
                  onPageChange={handleResponsePageChange}
                  nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                  previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                />
              </div>
            </div>

            {(user?.user?.permissions?.includes("employee-add-aciton-logs") || user?.user?.role === "setting_user") && (
              <div className="card col-span-12 xl:col-span-4 ">
                <div className="flex items-center justify-between">
                  <div className="flex gap-x-2">
                    <Heading heading="Actions Log " />
                    <h2 className="pt-1.5 text-sm text-grayColor300"> ({actionLogListingCount})</h2>
                    <ArrowPathIcon
                      onClick={() => {
                        getActionLogByEmployeeID();
                      }}
                      className="mt-1 h-5 w-5 cursor-pointer text-grayColor300"
                    />
                  </div>
                  <div>
                    <button
                      className="rounded-full bg-secondary px-6 py-2 text-sm text-white 3xl:text-base "
                      type="button"
                      onClick={() => setOpenActionLogModal({ open: true, data: null, editMode: false })}
                    >
                      Add +
                    </button>
                  </div>
                </div>

                <div className="-mx-6 mb-2 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD_ACTION_LOGS?.map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "Date") {
                                sortKey = "created_at_unix";
                              } else if (head === "Action") {
                                sortKey = "note";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              requestSortActionLogs(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {sortConfigActionLogs.key === (head === "Date" ? "created_at_unix" : head === "Action" ? "note" : head.toLowerCase()) &&
                              sortConfigActionLogs.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {actionLogLoading ? (
                        <tr>
                          <td colSpan="6">
                            <Skeleton count={itemsPerPage} height={50} />
                          </td>
                        </tr>
                      ) : itemsActionLogs?.length > 0 ? (
                        itemsActionLogs?.map((item, index) => (
                          <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                            <td className="py-3 pl-6 3xl:pr-4">
                              <p className="text-xs font-normal text-primary-color-200 ">
                                {moment.unix(item?.created_at_unix).format("D MMM YYYY h:mm A")}
                              </p>
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 ">
                                {item?.note?.length > shortDescription ? (
                                  <div className="cursor-pointer" onClick={() => setOpenReadMoreMessageModal({ open: true, data: item?.note })}>
                                    <EllipsisText text={item?.note} maxLength={shortDescription} />
                                  </div>
                                ) : (
                                  <EllipsisText text={item?.note} maxLength={shortDescription} />
                                )}
                                {/* {item?.note} */}
                              </p>
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex items-center gap-x-2">
                                <button
                                  className="edit-button text-xs font-medium text-secondary underline underline-offset-2"
                                  type="button"
                                  onClick={() => setOpenActionLogModal({ open: true, data: item, editMode: true })}
                                >
                                  Edit
                                </button>
                                <button
                                  className="edit-button text-xs font-medium text-red-500 underline underline-offset-2"
                                  type="button"
                                  onClick={() => setOpenDeleteActionLogModal({ open: true, label: "Delete", id: item?.id })}
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className=" h-[200px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    previousClassName="item previous"
                    pageCount={totalActiveLogPages}
                    pageClassName="item pagination-page"
                    forcePage={currentActiveLogPage - 1}
                    onPageChange={handleActiveLogPageChange}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="card col-span-12 space-y-1">
            <div className="flex items-center justify-between">
              <div className="flex gap-x-2">
                <Heading heading="Notification History" />
                <h2 className="pt-1.5 text-sm text-grayColor300"> ({notificationHistoryDataCount})</h2>
                <ArrowPathIcon
                  onClick={() => {
                    getNotificationsHistory();
                  }}
                  className="mt-1 h-5 w-5 cursor-pointer text-grayColor300"
                />
              </div>
              <RangePicker setRangePicker={setNotificationHistoryDateRange} width="xl:w-80" />
            </div>
            <div className="-mx-6 mb-12 h-[21rem] 3xl:h-[28rem]">
              <table className="w-full text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD_NOTIFICATION_HISTORY?.map((head) => (
                      <th
                        key={head}
                        className="border-b border-gray-100 bg-white p-4 first:pl-6"
                        onClick={() => {
                          let sortKey;
                          if (head === "Title") {
                            sortKey = "title";
                          } else if (head === "Link") {
                            sortKey = "url";
                          } else if (head === "Description") {
                            sortKey = "message";
                          } else if (head === "Created at") {
                            sortKey = "created_date_unix";
                          } else {
                            sortKey = head.toLowerCase();
                          }
                          requestSortNotificationHistory(sortKey);
                          //
                        }}
                      >
                        <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                          {head}
                          {sortConfigNotificationHistory.key ===
                            (head === "Title"
                              ? "title"
                              : head === "Link"
                                ? "url"
                                : head === "Description"
                                  ? "message"
                                  : head === "Created at"
                                    ? "created_date_unix"
                                    : head.toLowerCase()) && sortConfigNotificationHistory.direction === "asc" ? (
                            <ChevronUpIcon className="inline-block h-4 w-3.5" />
                          ) : (
                            <ChevronDownIcon className="inline-block h-4 w-3.5" />
                          )}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {notificationHistoryLoading ? (
                    <tr>
                      <td colSpan="6">
                        <Skeleton count={itemsPerPage} height={50} />
                      </td>
                    </tr>
                  ) : itemsNotificationHistory?.length > 0 ? (
                    itemsNotificationHistory?.map((item, index) => (
                      <tr key={item?.id} className={`transition-all duration-300`}>
                        <td className="py-3 pl-6 3xl:pr-4">
                          <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">{item?.title || "N/A"}</p>
                        </td>
                        <td className="py-3 pl-2 3xl:pl-4">
                          <p className={`text-10 font-normal ${item?.url ? "text-urlTextColor underline" : "text-primary-color-200"}  2xl:text-xs `}>
                            {item?.url || "N/A"}
                          </p>
                        </td>
                        <td className="py-3 pl-6 3xl:pr-4">
                          <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                            {" "}
                            {item?.is_broadcast === 1 && item?.type === "Reminder"
                              ? "Broadcast"
                              : item?.is_broadcast === 0 && item?.type === "Reminder"
                                ? "Individual"
                                : "" || "N/A"}
                          </p>
                        </td>

                        <td className="py-3 pl-2 3xl:pl-4">
                          <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                            {item?.message?.length > shortDescription ? (
                              <div className="cursor-pointer" onClick={() => setOpenReadMoreNotificationHistoryModal({ open: true, data: item })}>
                                <EllipsisText text={item?.message} maxLength={shortDescription} />
                              </div>
                            ) : (
                              <EllipsisText text={item?.message} maxLength={shortDescription} />
                            )}
                          </p>
                        </td>
                        <td className="py-3 pl-2 3xl:px-4">
                          <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                            {moment.unix(item?.created_date_unix).format("D MMM, YYYY")}
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className=" h-[250px]">
                      <td colSpan="6">
                        <Lottie options={emptyFolderAnimation} width={200} height={200} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="absolute bottom-4">
              <ReactPaginate
                breakLabel="..."
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                activeClassName="active"
                nextClassName="item next"
                renderOnZeroPageCount={null}
                breakClassName="item break-me "
                containerClassName="pagination"
                previousClassName="item previous"
                pageCount={totalNotificationHistoryPages}
                pageClassName="item pagination-page"
                forcePage={currentNotificationHistoryPage - 1}
                onPageChange={handleNotificationHistoryPageChange}
                nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Update Employee */}
      <AddManuallyModal
        onSuccess={getEmployeeDetail}
        data={openUpdateEmployeeModal?.data}
        isOpen={openUpdateEmployeeModal.open}
        editMode={openUpdateEmployeeModal.editMode}
        setIsOpen={(isOpen) => setOpenUpdateEmployeeModal({ ...openUpdateEmployeeModal, open: isOpen })}
      />

      {/* Responses reasons detail modal */}
      <NotificationModal
        data={openNotificationMessageModal.data}
        isOpen={openNotificationMessageModal.open}
        setIsOpen={(isOpen) => setOpenNotificationMessageModal({ ...openNotificationMessageModal, open: isOpen })}
      />

      {/* Send Message Modal */}
      <NotifyUserModal
        data={openNotifyUserModal.data}
        isOpen={openNotifyUserModal.open}
        setIsOpen={(open) => setOpenNotifyUserModal((prev) => ({ ...prev, open }))}
      />

      {/* Mental Health Rest Day Modal */}
      <MentalHealthAbsenceModal
        data={openMentalHealthAbsenceModal.label}
        isOpen={openMentalHealthAbsenceModal.open}
        label={openMentalHealthAbsenceModal.label}
        setIsOpen={(open) => setOpenMentalHealthAbsenceModal((prev) => ({ ...prev, open }))}
        refreshData={() => getEmployeeResponsesListing()}
      />

      {/* Questioneries Modal */}
      <ConfirmationModal
        handleSubmit={sendQuestionnaireFormById}
        isOpen={openQuestioneriesModal.open}
        label={openQuestioneriesModal.label}
        setIsOpen={(open) => setOpenQuestioneriesModal((prev) => ({ ...prev, open }))}
      />

      {/* Delete Account Modal */}
      <ConfirmationModal
        handleSubmit={handleDeleteAccount}
        isOpen={openDeleteAccountModal.open}
        label={openDeleteAccountModal.label}
        innerLabel={openDeleteAccountModal.innerLabel}
        setIsOpen={(open) => setOpenDeleteAccountModal((prev) => ({ ...prev, open }))}
      />

      {/* Check In Form Modal */}
      <CheckInFormModal
        handleSubmit={sendWellBeingCheckInFormByID}
        selectedDate={checkInDate}
        data={openCheckInFormModal.data}
        isOpen={openCheckInFormModal.open}
        label={openCheckInFormModal.label}
        message={message}
        setMessage={setMessage}
        handleDateChange={handleDateChange}
        setIsOpen={(open) => setOpenCheckInFormModal((prev) => ({ ...prev, open }))}
      />

      {/* Action Logs Modal */}
      <ActionLogModal
        isOpen={openActionLogModal.open}
        data={openActionLogModal.data}
        editMode={openActionLogModal.editMode}
        setIsOpen={(open) => setOpenActionLogModal((prev) => ({ ...prev, open }))}
        onSuccess={getActionLogByEmployeeID}
      />

      {/* Delete Action Log Modal*/}
      <ConfirmationModal
        handleSubmit={deleteActionLog}
        isOpen={openDeleteActionLogModal.open}
        label={openDeleteActionLogModal.label}
        setIsOpen={(open) => setOpenDeleteActionLogModal((prev) => ({ ...prev, open }))}
      />

      {/*Check In form Fill Modal*/}
      <CheckInFormFillModal
        data={openCheckInFormFillModal.data}
        isOpen={openCheckInFormFillModal.open}
        setIsOpen={(open) => setOpenCheckInFormFillModal((prev) => ({ ...prev, open }))}
        onSuccess={getEmployeeConsultationForm}
      />

      {/* Active Deactive  Modal */}
      <ConfirmationModal
        isOpen={openStatusChangeModal.open}
        setIsOpen={(open) => setOpenStatusChangeModal((prev) => ({ ...prev, open }))}
        label={openStatusChangeModal.label}
        handleSubmit={handleActiveDeactiveEmployee}
      />

      {/* View Form Modal */}

      <ViewConsultationFormModal
        isOpen={openConsultationFormModal?.open}
        data={openConsultationFormModal?.data}
        setIsOpen={(open) => setOpenConsultationFormModal((prev) => ({ ...prev, open }))}
      />

      <ReadMoreModal
        data={openReadMoreMessageModal.data}
        isOpen={openReadMoreMessageModal.open}
        setIsOpen={(isOpen) => setOpenReadMoreMessageModal({ ...openReadMoreMessageModal, open: isOpen })}
        name="Action Log"
      />
      <ReadNotificationHistoryModal
        data={openReadMoreNotificationHistoryModal.data}
        isOpen={openReadMoreNotificationHistoryModal.open}
        setIsOpen={(isOpen) => setOpenReadMoreNotificationHistoryModal({ ...openReadMoreNotificationHistoryModal, open: isOpen })}
      />
    </>
  );
};

export default EmployeeDetail;
