import Spinner from "./Spinner";
import Lottie from "react-lottie";
import ApiServices from "../api/services";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import { employeeMediaUrl } from "../utils/config";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import { useSortableData } from "../hooks/useSortableData";
import animationData from "../assets/lotties/empty-folder";
import { toUTCUnixTimestamp } from "../helperFunctions/helper";
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import moment from "moment";
// Table Head
const TABLE_HEAD = ["Employee ID", "Name", "Site", "Email"];

// Reole List of Array
const rolesList = [
  "dashboard-view-audit-report",
  "dashboard-view-recent-responses",
  "dashboard-view-pending-forms",
  "profile-edit",
  "profile-update-payment-method",
  "profile-change-plan",
  "profile-cancel-auto-renewal",
  "profile-view-billing-history",
  "campus",
  "department",
  "admins-make-remove",
  "employee-add-edit-delete",
  "employee-invite-users",
  "employee-notify-user",
  "employee-see-user-detail",
  "employee-mark-as-mental-health-absence",
  "employee-add-aciton-logs",
  "employee-view-submitted-forms",
  "cause-for-concern-employee-with-multiple-low-check-ins",
  "cause-for-concern-notify-user",
  "report-generate",
  "report-download-share",
  "report-view-history",
  "constact-book-add-new-contact",
  "contact-book-edit-delete",

  "broadcast-notification-send",
  "support-ticket-create-new-ticket",
  "support-ticket-view-ticket",
  "support-ticket-respond-ticket",
  "support-ticket-change-status",
];

const MakeNewAdminModal = ({ isOpen, setIsOpen, data, onSuccess, editMode }) => {
  // Context
  const { btnLoading, loading, setBtnLoading, openSuccessModal, setLoading } = useLoggedContext();

  // Use States
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [adminListing, setAdminListing] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [localData, setLocalData] = useState({ roles: [] });
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [selectedDateError, setSelectedDateError] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState(rolesList.map(() => false));
  const [allCampusChecked, setAllCampusChecked] = useState(false);
  const [userSelectionErr, setUserSelectionErr] = useState("");

  const [permissionErr, setPermissionErr] = useState("");

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(adminListing);

  // Validation
  const isValidForm = () => {
    let isValidData = true;

    // if (!selectedDate) {
    //   setSelectedDateError("Required");
    //   isValidData = false;
    // }

    if (checkedItems.length === 0) {
      setUserSelectionErr("Please select at least one contact");
      isValidData = false;
    }

    if (selectedRoles.length === 0) {
      setPermissionErr("Please select at least one permission");
      isValidData = false;
    }

    return isValidData;
  };

  // Related to Table Checkbox
  const handleCheckboxChangeEmployee = (item) => {
    if (isChecked(item)) {
      setCheckedItems(checkedItems.filter((checkedItemId) => checkedItemId !== item?.id));
      setSelectAllChecked(false); // Uncheck "Select All" when any individual checkbox is unchecked
    } else {
      setCheckedItems([...checkedItems, item?.id]);
    }
  };

  const handleAllCheckboxChange = (isChecked) => {
    if (isChecked) {
      setCheckedItems(items.map((item) => item?.id));
      setSelectAllChecked(true); // Check "Select All" when all individual checkboxes are checked
    } else {
      setCheckedItems([]);
      setSelectAllChecked(false); // Uncheck "Select All" when all checkboxes are unchecked
    }
  };

  const isChecked = (item) => {
    return checkedItems.includes(item?.id);
  };

  // Related to Permissions
  const handleSelectAllChange = () => {
    setPermissionErr("");
    const selectAllValue = !checkboxValues.every((value) => value);
    setCheckboxValues(rolesList.map(() => selectAllValue));
    setSelectedRoles(selectAllValue ? rolesList : []);
  };

  const handleCheckboxChange = (index) => {
    setPermissionErr("");
    setCheckboxValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = !newValues[index];

      // Update selected roles state based on the checked checkboxes
      const updatedSelectedRoles = rolesList.filter((role, i) => newValues[i]);
      setSelectedRoles(updatedSelectedRoles);

      return newValues;
    });
  };

  // Handle Date Change
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setSelectedDateError("");
  };

  const handleDateCheckboxChange = () => {
    setShowDatepicker(!showDatepicker);
  };

  const handleCampusCheckboxChange = () => {
    setAllCampusChecked(!allCampusChecked);
  };

  // Get All Admin API
  const getAllAdmins = async (emptySearch) => {
    let payload = {
      search: emptySearch ? "" : searchText,
      // page: currentPageAllAdmins,
      records_no: 100000000,
    };
    setLoading(true);
    const res = await ApiServices.admins
      .getAdmins(payload)
      .then((res) => {
        const { data, message } = res;

        if (data?.code === 200) {
          setLoading(false);
          setAdminListing(data?.data?.data);
          // setCurrentPageAllAdmins(data?.data?.current_page);
          // setTotalPageAllAdmins(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {});
  };

  // Add New Admin API
  const AddNewAdmin = () => {
    const isValid = isValidForm();
    if (!isValid) return;

    if (checkedItems) {
      const requestData = {
        user_ids: checkedItems,
        permissions: selectedRoles,
        all_campus_status: allCampusChecked === true ? 1 : 0,
        expire_date: toUTCUnixTimestamp(selectedDate),
      };

      setBtnLoading(true);

      ApiServices.admins
        .AddNewAdmin(requestData)
        .then((res) => {
          const { data, message } = res;
          // console.log("🚀 ~ .then ~ data:", data);
          if (res?.code === 200) {
            setBtnLoading(false);
            setIsOpen(false);
            // onSuccess()
            // setSelectedDate("");
            // setCheckedItems([]);
            closeModal();
            openSuccessModal({
              title: "Success!",
              message: "Admin Permissions has been Created",
              onPress: (close) => {
                close();
                onSuccess();
              },
            });
          } else {
            // Handle error
            // setMessage({ text: "Something went wrong!", color: "" });
            setBtnLoading(false);
          }
        })
        .catch((err) => {
          setBtnLoading(false);
        });
    }
  };

  // Update Admin API
  const UpdateAdmin = () => {
    const requestData = {
      mode: "update_admin",
      permissions: selectedRoles,
      all_campus_status: allCampusChecked === true ? 1 : 0,
      expire_date: toUTCUnixTimestamp(selectedDate),
    };

    if (selectedRoles.length === 0) {
      setPermissionErr("Please select at least one permission");
      return;
    }

    setBtnLoading(true);
    ApiServices.admins
      .updateAdmin(data?.id, requestData)
      .then((res) => {
        const { data, message } = res;
        console.log("🚀 ~ .then ~ data:", data);

        if (res?.code === 200) {
          setBtnLoading(false);
          setIsOpen(false);
          // onSuccess()
          // setSelectedDate("");
          // setCheckedItems([]);
          openSuccessModal({
            title: "Success!",
            message: "Admin Permissions has been Updated",
            onPress: (close) => {
              close();
              onSuccess();
            },
          });
          closeModal();
        } else {
          // Handle error
          // setMessage({ text: "Something went wrong!", color: "" });
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        setBtnLoading(false);
      });
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        await getAllAdmins();
      }
    }
  };

  // useEffects

  useEffect(() => {
    setSelectedDate("");
    setLocalData((prevData) => ({
      ...prevData,
      roles: [],
    }));
    setCheckboxValues(rolesList.map(() => false));
    setCheckedItems([]);
    setShowDatepicker(false);
    setSelectedRoles([]);
  }, [data === null]);

  // Edit Data UseEffect

  useEffect(() => {
    if (data) {
      setShowDatepicker(data?.employee[0].expire_date === null ? false : true);
      setAllCampusChecked(data?.employee[0]?.all_campus_status === 1 ? true : false);
      // let unixTimestamp = data?.employee[0].expire_date;

      // // Checking if the timestamp is in milliseconds
      // if (unixTimestamp?.toString().length > 10) {
      //   unixTimestamp = Math?.floor(unixTimestamp / 1000);
      // }

      // Checking if unixTimestamp is a number
      // if (typeof unixTimestamp === "number" && !isNaN(unixTimestamp)) {
      //   const dateObject = new Date(unixTimestamp * 1000); // Multiply by 1000 if it's in seconds
      //   const formattedDate = dateObject.toISOString().split("T")[0];
      setSelectedDate(data?.employee[0]?.expire_date === null ? "" : moment.unix(data?.employee[0]?.expire_date).format("YYYY-MM-DD"));
      // }
      setSelectedRoles((prevData) => {
        const updatedRoles = data?.permissions || [];
        return updatedRoles;
      });
      setLocalData((prevData) => {
        const updatedRoles = data?.permissions || [];
        return { ...prevData, roles: updatedRoles };
      });
    }
  }, [data]);

  useEffect(() => {
    if (Array.isArray(localData?.roles)) {
      const updatedCheckboxValues = rolesList?.map((role) => localData.roles.includes(role));
      setCheckboxValues(updatedCheckboxValues);
    }
  }, [localData?.roles, rolesList]);

  useEffect(() => {
    getAllAdmins();
  }, [isOpen]);

  // Close Modal
  const closeModal = () => {
    setIsOpen(false);
    if (data === null) {
      setSelectedDate("");
      setLocalData((prevData) => ({
        ...prevData,
        roles: [],
      }));
      setCheckboxValues(rolesList.map(() => false));
      setCheckedItems([]);
      setShowDatepicker(false);
      setSelectedRoles([]);
    }
    setSelectedDateError("");
    setUserSelectionErr("");
    setPermissionErr("");
  };

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel
                  className={`h-[90vh] w-full lg:h-[90vh] xl:h-[90vh] ${data ? "max-w-2xl" : "max-w-7xl"}  overflow-hidden rounded-2xl bg-white p-8 align-middle shadow-xl transition-all`}
                >
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      {data ? "Edit Admin" : "Add New Admin"}
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-primary-color-200" />
                  </div>

                  <div className={` ${data ? "grid-cols-1" : "grid-cols-12 "} mt-6 grid h-full  `}>
                    {!data && (
                      <div className={`${data ? "col-span-12" : "col-span-7"}  pr-3`}>
                        <div className="flex justify-between">
                          <h2>Select from employees</h2>
                          <div className="relative flex items-center ">
                            <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                              <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                            </div>

                            <input
                              type="search"
                              id="search"
                              name="search"
                              placeholder="Search..."
                              autocomplete="off"
                              value={searchText}
                              onChange={(e) => {
                                setSearchText(e.target.value);
                                if (e.target.value.trim() === "") {
                                  getAllAdmins(true);
                                }
                              }}
                              onKeyDown={handleSearch}
                              className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                            />
                          </div>
                        </div>

                        {userSelectionErr && <span className="ml-1 text-sm text-red-600">{userSelectionErr}</span>}

                        <div className="mt-6">
                          <div className="mb-8 -ml-8 -mr-3 h-[400px] overflow-y-auto xl:h-[530px] 2xl:h-[500px] 3xl:h-[670px]">
                            <table className="w-full text-left">
                              <thead className="sticky top-0">
                                <tr>
                                  {TABLE_HEAD.map((head) => (
                                    <>
                                      <th
                                        key={head}
                                        className="border-b border-gray-100 bg-white p-4 first:pl-6"
                                        onClick={() => {
                                          if (head !== "Employee ID") {
                                            let sortKey;
                                            if (head === "Employee ID") {
                                              sortKey = "employee.id";
                                            } else if (head === "Name") {
                                              sortKey = "employee.name";
                                            } else if (head === "Site") {
                                              sortKey = "employee.campus.name";
                                            } else {
                                              sortKey = head.toLowerCase();
                                            }
                                            requestSort(sortKey);
                                          }
                                        }}
                                      >
                                        {head === "Employee ID" ? (
                                          <div className="flex items-center">
                                            <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                  handleAllCheckboxChange(e.target.checked);
                                                  setUserSelectionErr("");
                                                }}
                                                checked={selectAllChecked}
                                                className="mr-2 rounded"
                                              />
                                              <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                                                {head}
                                              </p>
                                            </label>
                                            <p
                                              className="inline-block h-7 cursor-pointer"
                                              onClick={() => {
                                                let sortKey = "employee.id";
                                                requestSort(sortKey);
                                              }}
                                            >
                                              {sortConfig.key === "employee.id" && sortConfig.direction === "asc" ? (
                                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                                              ) : (
                                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                                              )}
                                            </p>
                                          </div>
                                        ) : (
                                          <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                                            {head}
                                            {sortConfig.key ===
                                              (head === "Employee ID"
                                                ? "employee.id"
                                                : head === "Name"
                                                  ? "employee.name"
                                                  : head === "Campus"
                                                    ? "employee.campus.name"
                                                    : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                                              <ChevronUpIcon className="inline-block h-4 w-3.5" />
                                            ) : (
                                              <ChevronDownIcon className="inline-block h-4 w-3.5" />
                                            )}
                                          </p>
                                        )}
                                      </th>
                                    </>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {loading ? (
                                  <tr>
                                    <td colSpan="6">
                                      <Skeleton count={5} height={50} />
                                    </td>
                                  </tr>
                                ) : (
                                  // First, perform the filtering and store in a variable
                                  (() => {
                                    const filteredItems = items?.filter((item) => item?.permissions?.length === 0 || item?.permissions === null);

                                    // Check if there are any items to display
                                    if (filteredItems?.length > 0) {
                                      return filteredItems?.map((item, index) => (
                                        <tr
                                          key={item?.id}
                                          className="cursor-pointer even:bg-gray-50 hover:bg-gray-50"
                                          onClick={() => {
                                            handleCheckboxChangeEmployee(item);
                                            setUserSelectionErr("");
                                          }}
                                        >
                                          <td className="py-4 pl-6 3xl:pr-4">
                                            <label className="flex cursor-pointer items-center" onClick={(e) => e.stopPropagation()}>
                                              <input
                                                type="checkbox"
                                                checked={isChecked(item)}
                                                onChange={() => {
                                                  handleCheckboxChangeEmployee(item);
                                                  setUserSelectionErr("");
                                                }}
                                                className="mr-2 rounded"
                                              />
                                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.emp_id}</p>
                                            </label>
                                          </td>
                                          <td className="py-4 pl-4 3xl:px-4">
                                            <div className="flex items-center">
                                              <div className="mr-3 h-7 w-7 shrink-0 rounded-full">
                                                <img
                                                  className="mr-3 h-full w-full rounded-full"
                                                  src={
                                                    item?.employee[0]?.profile_image_url
                                                      ? employeeMediaUrl + item?.employee[0]?.profile_image_url
                                                      : images.placeholder
                                                  }
                                                  alt="Profile"
                                                />
                                              </div>
                                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.name}</p>
                                            </div>
                                          </td>
                                          <td className="py-4 pl-4 pr-3 3xl:px-4">
                                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                              {item?.employee[0]?.campus?.name || "N/A"}
                                            </p>
                                          </td>
                                          <td className="py-4 pl-4 pr-3 3xl:px-4">
                                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.email}</p>
                                          </td>
                                        </tr>
                                      ));
                                    } else {
                                      // Render "No Data" message if no items passed the filter
                                      return (
                                        <tr className="h-96 3xl:h-[500px]">
                                          <td colSpan="6">
                                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })()
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={`${data ? "" : "col-span-5 h-[90%] border-l border-primary-color-300 pl-5"}`}>
                      {/* premissions work */}
                      <div>
                        <div className="flex items-center ">
                          <h2 className="w-1/3 text-sm font-medium text-secondary-color 3xl:text-base">All site</h2>
                          <label htmlFor="allCampus" className="flex w-2/3 cursor-pointer items-center gap-4">
                            <input
                              onChange={handleCampusCheckboxChange}
                              type="checkbox"
                              className="h-4 w-4 rounded border-primary-color-100"
                              id="allCampus"
                              checked={allCampusChecked}
                            />

                            <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Allow to manage all site</strong>
                          </label>
                        </div>
                      </div>

                      <div className="flex border-b border-primary-color-300 py-6 3xl:py-8">
                        <div className="w-1/3">
                          <div className="flex items-center">
                            <h2 className="text-sm font-medium text-secondary-color 3xl:text-base">Access expiry </h2>
                            {selectedDateError && <span className="ml-1 text-sm text-red-600">{selectedDateError}</span>}
                          </div>
                        </div>
                        <div className="">
                          <label htmlFor="Option1" className="flex cursor-pointer items-center gap-4">
                            <input
                              onChange={handleDateCheckboxChange}
                              type="checkbox"
                              className="h-4 w-4 rounded border-primary-color-100"
                              id="Option1"
                              checked={showDatepicker}
                            />

                            <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Set access expiry date </strong>
                          </label>

                          {showDatepicker && (
                            <input
                              id="date"
                              type="date"
                              name="date"
                              value={selectedDate}
                              onChange={handleDateChange}
                              className="mt-4 h-8 rounded-lg border border-primary-color-100 text-xs 3xl:h-auto 3xl:text-sm"
                              min={new Date().toISOString().split("T")[0]}
                            />
                          )}
                        </div>
                      </div>

                      {/* Permissions */}
                      <div className="mt-5 mb-1">
                        <h2 className="text-base font-medium text-secondary-color 3xl:text-xl">
                          Permissions {permissionErr && <span className="ml-1 text-sm font-normal text-red-600">{permissionErr}</span>}
                        </h2>

                        <p className="mt-2 text-xs text-primary-color-100 3xl:text-sm">
                          Permission refers to the authorisation or rights granted to a user or a role to perform specific actions or access certain
                          functionalities within the application.
                        </p>
                      </div>

                      {/* scrolable div */}
                      <div
                        className={`-mr-6 ${showDatepicker ? "h-[420px] lg:h-[200px] xxl:h-[320px] 3xl:h-[400px]" : "h-[480px] lg:h-[240px] xxl:h-[360px] 3xl:h-[490px]"} overflow-auto `}
                      >
                        {/* App access Checkbox */}
                        <div className={`flex border-b border-primary-color-300 py-6 3xl:py-8 `}>
                          <div className="w-1/3">
                            <h2 className="text-sm font-medium text-secondary-color 3xl:text-base">App Access</h2>
                          </div>
                          <div className="w-[65%]">
                            <label htmlFor="appAccess" className="flex cursor-pointer items-start gap-4">
                              <input
                                id="appAccess"
                                checked={checkboxValues.every((value) => value)}
                                onChange={handleSelectAllChange}
                                type="checkbox"
                                className="size-4 mt-1.5 rounded border-primary-color-100"
                              />
                              <div className="space-y-1">
                                <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Admin (all permissions) </strong>
                                <p className="text-xs text-primary-color-100 3xl:text-sm">
                                  All permission refers to the all authorisation or rights granted to admin to perform specific actions or access
                                  certain functionalities within the application.
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div>
                          {rolesList.map((role, index) => (
                            <>
                              <div className={`flex`}>
                                <div className={`w-1/3 pb-3 ${index === 0 && "pt-6"}`}>
                                  {role === "dashboard-view-audit-report" && (
                                    <h2 className="text-sm font-medium text-secondary-color 3xl:text-base" key={index}>
                                      Dashboard
                                    </h2>
                                  )}
                                  {role === "profile-edit" && <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Profile</h2>}

                                  {role === "campus" && <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Site</h2>}
                                  {role === "department" && <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Department</h2>}
                                  {role === "admins-make-remove" && (
                                    <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base`}>Admins</h2>
                                  )}
                                  {role === "employee-add-edit-delete" && (
                                    <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base`}>Employees</h2>
                                  )}
                                  {role === "cause-for-concern-employee-with-multiple-low-check-ins" && (
                                    <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Cause for Concern</h2>
                                  )}
                                  {role === "report-generate" && (
                                    <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Reports</h2>
                                  )}
                                  {role === "constact-book-add-new-contact" && (
                                    <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Contact Book</h2>
                                  )}
                                  {role === "broadcast-notification-send" && (
                                    <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Broadcast Notifications</h2>
                                  )}
                                  {role === "support-ticket-create-new-ticket" && (
                                    <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base `}>Support Tickets</h2>
                                  )}
                                </div>

                                <div className={`w-[63%] pb-3 ${index === 0 && "pt-6"}`}>
                                  {role === "dashboard-view-audit-report" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Audit report</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Access and review the audit report for a detailed account of platform activities.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "dashboard-view-recent-responses" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Recent responses</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Monitor recent user responses within the platform.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "dashboard-view-pending-forms" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View pending forms</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Access and manage forms that are currently pending or require attention.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "profile-edit" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Edit Profile</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Modify user profile information.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "profile-update-payment-method" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Update payment method</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Manage and update the payment method associated with the user account.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "profile-change-plan" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Change plan</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Switch to a different subscription plan.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "profile-cancel-auto-renewal" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Cancel auto-renewal</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Opt-out of automatic subscription renewal.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "profile-view-billing-history" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Billing history</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Access a history log of billing-related activities.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "campus" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Site</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Authorize to Modify Site</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "department" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Department</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Authorize to Modify Department</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "admins-make-remove" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Make/remove admins</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Assign or remove administrative privileges for user accounts.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "employee-add-edit-delete" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                            Add / Edit / Delete employee
                                          </strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Manage the employee database with the ability to add, edit, or delete employee records.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "employee-invite-users" && (
                                    <>
                                      <label key={index} className={`flex cursor-pointer items-start gap-4 `}>
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Invite users</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Send invitations to users for platform access.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "employee-notify-user" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Notify user</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Send notifications to users within the system.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "employee-see-user-detail" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">See User Details</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Access detailed information about employees.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "employee-mark-as-mental-health-absence" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                            Mark as Mental Health Rest Day
                                          </strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Indicate employee mental health rest days.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "employee-add-aciton-logs" && (
                                    <>
                                      <label key={index} className={`} flex cursor-pointer items-start gap-4`}>
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Add action logs</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Record and track actions taken within the system.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "employee-view-submitted-forms" && (
                                    <>
                                      <label key={index} className={`} flex cursor-pointer items-start gap-4`}>
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View submitted forms</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Access and review forms submitted by employees.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "cause-for-concern-employee-with-multiple-low-check-ins" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                            Employees with Multiple Low Check-ins
                                          </strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Identify and manage employees with multiple low check-ins.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "cause-for-concern-notify-user" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Notify users</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Send notifications to users within the system.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "report-generate" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Generate reports</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Create reports based on platform data.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "report-download-share" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Download / Share reports</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Save or share generated reports.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "report-view-history" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View History</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Access the history log for reporting activities.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "constact-book-add-new-contact" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Add new contact</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Add new contacts to the platform.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "contact-book-edit-delete" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Edit / Delete contact</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Modify or remove existing contacts.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "broadcast-notification-send" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                            Send broadcast notifications
                                          </strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Authorize the sending of notifications to a broad audience.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "support-ticket-create-new-ticket" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Create new ticket</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Initiate the creation of new support tickets.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "support-ticket-view-ticket" && (
                                    <>
                                      <label key={index} className={`flex cursor-pointer items-start gap-4 `}>
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Tickets</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Access and monitor support tickets.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "support-ticket-respond-ticket" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Respond ticket</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                                            Provide responses and solutions to support tickets.
                                          </p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                  {role === "support-ticket-change-status" && (
                                    <>
                                      <label key={index} className="flex cursor-pointer items-start gap-4">
                                        <input
                                          type="checkbox"
                                          checked={checkboxValues[index]}
                                          onChange={() => handleCheckboxChange(index)}
                                          className="size-4 mt-1.5 rounded border-primary-color-100"
                                        />
                                        <div className="space-y-1">
                                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Change status</strong>
                                          <p className="text-xs text-primary-color-100 3xl:text-sm">Modify the status of support tickets.</p>
                                        </div>
                                      </label>
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      {/* bottom button */}
                      <div className="mt-4 flex cursor-pointer items-center justify-end gap-x-4">
                        <span onClick={closeModal} className="text-sm font-medium text-secondary-color 3xl:text-base">
                          Cancel
                        </span>
                        {data ? (
                          <>
                            <button
                              onClick={UpdateAdmin}
                              className="rounded-full bg-secondary px-16 py-2 text-sm text-white 3xl:text-base"
                              type="button"
                            >
                              {btnLoading ? <Spinner /> : "Update"}
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={AddNewAdmin}
                              className="rounded-full bg-secondary px-16 py-2 text-sm text-white 3xl:text-base"
                              type="button"
                            >
                              {btnLoading ? <Spinner /> : "Save"}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default MakeNewAdminModal;
