import { async } from "q";
import moment from "moment";
import Lottie from "react-lottie";
import Badge from "../components/Badge";
import { Tooltip } from "react-tooltip";
import Button from "../components/Button";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { images } from "../assets/styles/Images";
import React, { useState, useEffect } from "react";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { employeeMediaUrl, mediaUrl } from "../utils/config";
import MakeNewAdminModal from "../components/MakeNewAdminModal";
import ConfirmationModal from "../components/ConfirmationModal";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";

// Table Head
const TABLE_HEAD = ["Employee ID", "Site", "Name", "Email", "Expires At", "Action"];

const Admins = () => {
  // React Router Dom Hooks
  const navigate = useNavigate();

  // Context
  const { loading, setLoading, openSuccessModal, setBtnLoading, employeesListing, user } = useLoggedContext();

  // useStates
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [openNewAdminModal, setOpenNewAdminModal] = useState({ open: false, data: null, editMode: false });
  const [openRemoveAdminModal, setOpenRemoveAdminModal] = useState(false);
  const [removeAdminId, setRemoveAdminId] = useState("");

  const [totalCount, setTotalCount] = useState(0);
  // <------- Table Sorting ----------->

  const [adminListing, setAdminListing] = useState([]);

  // Active Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(adminListing);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 9 : isLaptopMedium ? 6 : isLaptop ? 6 : 10;

  // Get Admins Lists
  const getAllAdmins = async (emptySearch) => {
    let payload = {
      search: emptySearch ? "" : searchText,
      role: "setting_user_employee",
      page: currentPage,
      records_no: itemsPerPage,
    };

    setLoading(true);

    const res = await ApiServices.admins
      .getAdmins(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setLoading(false);
          setAdminListing(data?.data?.data);
          setCurrentPage(data?.data?.current_page);
          // setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {});
  };

  // Get Admin Counts
  const getAdminCounts = async () => {
    const res = await ApiServices.admins
      .getAdminCounts()
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setTotalCount(data.data);
          setTotalPages(Math.ceil(data?.data / itemsPerPage));
        }
      })
      .catch((err) => {});
  };

  // Remove Admin
  const getRemoveAdmin = () => {
    setBtnLoading(true);

    ApiServices.admins
      .removeAdmin(removeAdminId)
      .then((res) => {
        const { data, message } = res;
        if (res?.code === 200) {
          getAllAdmins();
          getAdminCounts();
          setBtnLoading(false);
          setOpenRemoveAdminModal(false);
          openSuccessModal({
            title: "Success!",
            message: "Admin removed successfully",
            onPress: (close) => {
              close();
              getAllAdmins();
            },
          });
        }
      })
      .catch((err) => {});
  };

  // Pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        await getAllAdmins();
        setCurrentPage(1);
      }
    }
  };

  useEffect(() => {
    getAllAdmins();
  }, [currentPage]);

  useEffect(() => {
    getAdminCounts();
  }, []);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="card h-[82vh] 3xl:h-[84vh]">
        <div className="mb-6">
          <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-6 bg-white md:flex-row md:items-center">
            <div className="flex items-center gap-x-2">
              <Heading
                heading={`Admin (${totalCount})`}
                icon
                handleRefresh={() => {
                  getAllAdmins();
                  getAdminCounts();
                }}
              />
            </div>

            {/* Search */}

            <div className="flex items-center justify-center gap-x-2 md:mt-0 2xl:gap-x-4">
              <Button
                title="Make new Admin"
                btnSmall
                onClick={() => {
                  setOpenNewAdminModal({ open: true, data: null, editMode: false });
                }}
              />

              <div className="relative flex items-center ">
                <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                  <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                </div>

                <input
                  type="search"
                  id="search"
                  name="search"
                  placeholder="Search..."
                  autocomplete="off"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value.trim() === "") {
                      getAllAdmins(true);
                    }
                  }}
                  onKeyDown={handleSearch}
                  className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                />
              </div>
              {/* select */}
            </div>
          </div>
          <Heading subheading="Details of active settings & pending for approval.  " />
        </div>

        <div className="-mx-6 mb-8 overflow-x-auto">
          <table className="w-full text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="w-38 border-b border-gray-100 bg-white p-4 first:pl-6"
                    onClick={() => {
                      let sortKey;
                      if (head === "Employee ID") {
                        sortKey = "employee.id";
                      } else if (head === "Name") {
                        sortKey = "employee.name";
                      } else if (head === "Expires at") {
                        sortKey = "employee.expiresAt";
                      } else if (head === "Site") {
                        sortKey = "employee.campus.name";
                      } else {
                        sortKey = head.toLowerCase();
                      }
                      requestSort(sortKey);
                    }}
                  >
                    <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                      {head}
                      {sortConfig.key ===
                        (head === "Employee ID"
                          ? "employee.id"
                          : head === "Name"
                            ? "employee.name"
                            : head === "Site"
                              ? "employee.campus.name"
                              : head === "Expires at"
                                ? "employee.expiresAt"
                                : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                        <>{head === "Action" ? "" : <ChevronUpIcon className="inline-block h-4 w-3.5" />}</>
                      ) : (
                        <>{head === "Action" ? "" : <ChevronDownIcon className="inline-block h-4 w-3.5" />}</>
                      )}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <Skeleton count={itemsPerPage} height={50} />
                  </td>
                </tr>
              ) : (
                (() => {
                  const filteredItems = items?.filter((item) => item?.permissions?.length > 0);

                  if (filteredItems?.length > 0) {
                    return filteredItems?.map((item, index) => (
                      <tr key={item?.id} className="even:bg-gray-50 hover:bg-gray-50">
                        <td className="py-3 pl-6 pr-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.emp_id}</p>
                        </td>
                        <td className="py-3 pl-4 pr-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.campus?.name || "N/A"}</p>
                        </td>
                        <td
                          className="cursor-pointer py-3 pl-4 3xl:px-4"
                          onClick={() =>
                            navigate(item?.id ? `/employees/${item?.id}` : "1", {
                              state: { employeeDetail: item },
                            })
                          }
                        >
                          <div className="flex items-center">
                            <div className="mr-3 h-8 w-8 rounded-full">
                              <img
                                className="mr-3 h-full w-full rounded-full"
                                src={
                                  item?.employee[0]?.profile_image_url === null || item?.employee[0]?.profile_image_url === ""
                                    ? images.placeholder
                                    : employeeMediaUrl + item?.employee[0]?.profile_image_url
                                }
                                alt="Profile"
                              />
                            </div>
                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.name}</p>
                          </div>
                        </td>
                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.email}</p>
                        </td>
                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                            {item?.employee[0]?.expire_date !== null ? moment.unix(item?.employee[0]?.expire_date).format("D MMM YYYY") : "No Expiry"}
                            {item?.employee[0]?.expire_date === null ? (
                              ""
                            ) : (
                              <Tooltip
                                anchorSelect={`#date${index + 1}`}
                                id={`date-tooltip${index + 1}`}
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="bottom"
                              >
                                <span>{moment.unix(item?.employee[0]?.expire_date).format("D MMM YYYY h:mm A")}</span>
                              </Tooltip>
                            )}{" "}
                          </p>
                        </td>
                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <span
                            onClick={() => setOpenNewAdminModal({ open: true, data: item })}
                            className="undreline cursor-pointer pr-4 text-xs font-normal text-secondary underline underline-offset-4 3xl:text-sm"
                          >
                            Edit
                          </span>
                          {(user?.user?.permissions?.includes("admins-make-remove") || user?.user?.role === "setting_user") && (
                            <span
                              onClick={() => {
                                setOpenRemoveAdminModal(true);
                                setRemoveAdminId(item?.id);
                              }}
                              className="undreline cursor-pointer text-xs font-normal text-red-500 underline underline-offset-4 3xl:text-sm"
                            >
                              Remove as Admin
                            </span>
                          )}
                        </td>
                      </tr>
                    ));
                  } else {
                    // Render "No Data" message
                    return (
                      <tr className="h-[500px]">
                        <td colSpan="6">
                          <Lottie options={emptyFolderAnimation} width={200} height={200} />
                        </td>
                      </tr>
                    );
                  }
                })()
              )}
            </tbody>
          </table>
        </div>

        <div className="absolute bottom-6">
          <ReactPaginate
            breakLabel="..."
            pageRangeDisplayed={5}
            forcePage={currentPage - 1}
            marginPagesDisplayed={2}
            activeClassName="active"
            nextClassName="item next"
            renderOnZeroPageCount={null}
            breakClassName="item break-me "
            containerClassName="pagination"
            onPageChange={handlePageChange}
            previousClassName="item previous"
            pageClassName="item pagination-page "
            pageCount={totalPages}
            nextLabel={<ChevronRightIcon className="h-5 w-5" />}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
          />
        </div>
      </div>

      {/* Remove as Admin Modal */}
      <ConfirmationModal
        isOpen={openRemoveAdminModal}
        label="Remove this user as Admin"
        handleSubmit={getRemoveAdmin}
        setIsOpen={setOpenRemoveAdminModal}
      />

      {/* Make new Admin Modal */}
      <MakeNewAdminModal
        onSuccess={getAllAdmins}
        data={openNewAdminModal.data}
        isOpen={openNewAdminModal.open}
        editMode={openNewAdminModal.editMode}
        setOpenNewAdminModal={setOpenNewAdminModal}
        setIsOpen={(open) => setOpenNewAdminModal((prev) => ({ ...prev, open }))}
      />
    </>
  );
};

export default Admins;
