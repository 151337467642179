import Dropdown from "./Dropdown";

import { images } from "../assets/styles/Images";
import { dashboard } from "../routes/routesDefined";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import { useNavigate, useLocation } from "react-router-dom";
import { employeeMediaUrl, mediaUrl } from "../utils/config";

export default function AdminNavbar({ sidebarWidth, setShowBackDrop }) {
  const location = useLocation();

  let navigate = useNavigate();

  const { isLoggedin, setShowSidebar, user } = useLoggedContext();

  return (
    <>
      <nav
        className={
          " z-10 flex h-[10vh] bg-white py-4 md:shadow-card lg:pr-16 2xl:py-6 3xl:h-[8vh] " +
          (" md:ml-[" + sidebarWidth + "px] ") +
          (isLoggedin ? "fixed top-0 w-full" : "")
        }
      >
        <div className="container relative mx-auto flex max-w-full items-center justify-between">
          <div className=" top-[-5px] md:hidden"></div>

          <div className="ml-5 flex  items-center justify-center md:justify-start">
            <div className="lg:hidden">
              <button
                onClick={() => {
                  setShowSidebar((prev) => (prev === "left-0" ? "-left-64" : "left-0"));
                  setShowBackDrop((prev) => !prev);
                }}
              >
                <Bars3Icon className="h-7 w-7 cursor-pointer" />
              </button>
            </div>
            <div className="navbar-brand cursor-pointer pr-4" style={{ width: sidebarWidth + "px" }} onClick={() => navigate(dashboard)}>
              <img src={images.NewLogo} alt="Work well logo" className=" mx-auto w-[10rem] md:mx-0  md:w-[12rem] md:pl-[3rem] 2xl:w-[14rem]" />
            </div>
            <div className="h-[8vh] border-l border-secondary-color/25 pl-4 3xl:h-[6vh]">
              {/* <img src={images.National_Icon} alt="Work well logo" className="mr-5 w-10" /> */}
            </div>
            {/* <div>
              <img src={images.Award_Icon} alt="Work well logo " className="w-12" />
            </div> */}

            <div className={`text-grayColor500  px-5 text-sm font-medium leading-normal md:block  lg:text-lg `}>
              Cultivate wellness; inspire excellence
            </div>

            {/* <div className={`page-title text-grayColor500 hidden px-5 text-xl font-medium leading-normal md:block lg:px-6 2xl:px-10 2xl:text-2xl`}>
              {location.pathname === "/dashboard"
                ? "Dashboard"
                : location.pathname === "/employees"
                  ? "Employees"
                  : location.pathname === "/contact-book"
                    ? "Contact Book"
                    : location.pathname === "/admins"
                      ? "Admins"
                      : location.pathname === "/notifications"
                        ? "Broadcast Notifications"
                        : location.pathname === "/support-tickets"
                          ? "Support Tickets"
                          : location.pathname === "/reports"
                            ? "Reports"
                            : location.pathname === "/profile"
                              ? "Profile"
                              : location.pathname === "/cause-for-concern"
                                ? "Cause For Concern"
                                : ""}
            </div> */}
          </div>

          <div className="flex  items-center justify-end pr-4 lg:pr-0">
            <h2 className="text-grayColor500 mr-4 hidden text-sm font-medium leading-normal md:block  lg:text-lg ">
              Hi, {user?.user?.employee?.length > 0 ? user?.user?.employee[0]?.name : "N/A"}
            </h2>

            <Dropdown />

            <div className="flex flex-col items-center justify-center border-l border-secondary-color/25 pl-3">
              <img
                src={
                  user?.user?.nursery?.length > 0 &&
                  user?.user?.nursery[0]?.profile_image_url !== null &&
                  user?.user?.nursery[0]?.profile_image_url !== ""
                    ? `${mediaUrl}${user?.user?.nursery[0]?.logo}`
                    : images.placeholder
                }
                alt="studio"
                className="mb-2 h-10 w-10 rounded-full border border-secondary object-contain"
              />
              <p className="text-xs font-bold text-secondary-color">{user?.user?.nursery?.length > 0 ? user?.user?.nursery[0]?.name : "N/A"}</p>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
