import moment from "moment";
import Lottie from "react-lottie";
import Button from "../components/Button";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { Transition } from "@headlessui/react";
import AddCampus from "../components/AddCampus";
import { useMediaQuery } from "react-responsive";
import { images } from "../assets/styles/Images";
import InputField from "../components/InputField";
import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import AddDepartment from "../components/AddDepartment";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import ConfirmationModal from "../components/ConfirmationModal";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useRef } from "react";

// Table Head
const TABLE_HEAD = ["Name", "Sites", "Created at"];
const TABLE_HEAD2 = ["Sr.#", "Site Name", "Actions"];

const Department = () => {
  // Context
  const { loading, setLoading, openSuccessModal, setBtnLoading } = useLoggedContext();

  // useStates
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLaptopLarge = useMediaQuery({ minWidth: 1599 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 11 : isLaptopMedium ? 10 : isLaptop ? 7 : 10;

  const [openNewDepartmentModal, setOpenNewDepartmentModal] = useState({ open: false, data: null, editMode: false });

  const [removeCampusDepartment, setRemoveCampusDepartment] = useState({ open: false, data: null, message: null });
  const [removeDepartmentModal, setRemoveDepartmentModal] = useState({ open: false, data: null, message: null });
  const [selectedDepartmentIndex, setSelectedDepartmentIndex] = useState(false);
  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [items3, setItems3] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (item) => {
    setSelectedItems([...selectedItems, item]);
    setItems3(items3.filter((i) => i !== item));
    setDropdownOpen(false);
  };

  const handleRemove = (item) => {
    setItems3([...items3, item]);
    setSelectedItems(selectedItems.filter((i) => i !== item));
  };

  const [departmentList, setDepartmentList] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const { items, requestSort, sortConfig } = useSortableData(departmentList);

  const detail = departmentList?.find((item) => item?.id === (activeRow || departmentList[0]?.id));

  const formatedDetailData = detail?.campus?.map((campus) => ({
    label: campus.name,
    value: campus.id,
  }));

  const getDepartmentList = (emptySearch) => {
    setLoading(true);
    let payload = {
      mode: 0,
      search: emptySearch ? "" : searchText,
      page: currentPage,
      records_no: itemsPerPage,
    };

    ApiServices.department
      .getDepartments(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setDepartmentList(data?.data?.data);
          setCurrentPage(data?.data?.current_page);
          setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeCampus = async () => {
    setSelectedDepartmentIndex(true);
    let payload = {
      department_id: removeCampusDepartment?.data?.pivot.department_id,
      campus_id: removeCampusDepartment?.data?.pivot.campus_id,
    };

    setBtnLoading(true);

    ApiServices.department
      .removeCampusDepartment(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          getDepartmentList();
          setBtnLoading(false);
          setRemoveCampusDepartment({ open: false, data: null, message: null });
          openSuccessModal({
            title: "Success!",
            message: "Campus Deleted Successfully.",
            onPress: (close) => {
              close();
            },
          });
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        setRemoveCampusDepartment({ open: true, data: null, message: err.response.data.message });
      });
  };

  const removeDepartment = async () => {
    setBtnLoading(true);
    ApiServices.department
      .removeDepartment(removeDepartmentModal?.data)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          getDepartmentList();
          setBtnLoading(false);
          setRemoveDepartmentModal({ open: false, data: null, message: null });
          openSuccessModal({
            title: "Success!",
            message: "Department Deleted Successfully.",
            onPress: (close) => {
              close();
            },
          });
        }
      })
      .catch((err) => {
        setRemoveDepartmentModal({ open: true, data: null, message: err.response.data.message });
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedDepartmentIndex) {
      setActiveRow(items[0]?.id);
    }
  }, [items]);

  useEffect(() => {
    getDepartmentList();
  }, []);

  useEffect(() => {
    setActiveItemIndex(null);
  }, [activeRow, selectedDepartmentIndex]);

  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [searchText, setSearchText] = useState("");

  // Function to set the active item for deletion
  const handleDeleteClick = (index) => {
    setActiveItemIndex(index);
  };

  const handleCancelClick = () => {
    setActiveItemIndex(null);
  };

  const getCampusList = () => {
    let payload = {
      mode: 1,
    };

    ApiServices.campus
      .getCampuses(payload)
      .then((res) => {
        const { data, message } = res;
        if (data?.code === 200) {
          const formattedData = data?.data?.map((department) => ({
            label: department.name,
            value: department.id,
          }));
          const filteredData = formattedData?.filter((item) => !formatedDetailData?.some((detailItem) => detailItem?.label === item?.label));
          setItems3(filteredData);
        } else {
        }
      })
      .catch((err) => {});
  };

  const addCampus = async (detail) => {
    let payload = {
      campus_ids: selectedItems?.map((item) => item?.value),
      department_id: detail?.id,
    };

    ApiServices.department
      .addCampusDepartment(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          getDepartmentList();
          setSelectedItems([]);
          openSuccessModal({
            title: "Success!",
            message: "Campus Added Successfully.",
            onPress: (close) => {
              close();
            },
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCampusList();
  }, [activeRow, departmentList]);

  // Pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      if (searchText.trim() !== "") {
        await getDepartmentList(false);
        setCurrentPage(1);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getDepartmentList();
  }, [currentPage]);

  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="card col-span-12 h-[82vh] lg:col-span-7 2xl:col-span-6 3xl:h-[84vh]">
          <div className="mb-6 flex items-center justify-between">
            <div className="flex gap-x-3">
              <Heading heading="Departments" />
            </div>

            <div className="flex items-center gap-x-3">
              <Button
                title="Add New"
                btnSmall
                onClick={() => {
                  setOpenNewDepartmentModal({ open: true, data: null, editMode: false });
                }}
              />

              <div className="relative flex w-48 items-center  3xl:w-auto">
                <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                  <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                </div>
                <input
                  type="search"
                  id="search"
                  name="search"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value.trim() === "") {
                      getDepartmentList(true);
                    }
                  }}
                  onKeyPress={handleKeyPress}
                  className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                />
              </div>
            </div>
          </div>

          <div className="-mx-6 mb-8 overflow-x-auto">
            <table className="w-full text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      onClick={() => {
                        let sortKey;
                        if (head === "Name") {
                          sortKey = "name";
                        } else if (head === "Sites") {
                          sortKey = "campus_count";
                        } else if (head === "Created at") {
                          sortKey = "created_at_unix";
                        } else {
                          sortKey = head.toLowerCase();
                        }
                        requestSort(sortKey);
                      }}
                      className="border-b border-gray-100 bg-white p-4 first:pl-6"
                    >
                      <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                        {head}
                        {sortConfig.key ===
                          (head === "Name"
                            ? "name"
                            : head === "Sites"
                              ? "campus_count"
                              : head === "Created at"
                                ? "created_at_unix"
                                : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                          <ChevronUpIcon className="inline-block h-4 w-3.5" />
                        ) : (
                          <ChevronDownIcon className="inline-block h-4 w-3.5" />
                        )}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6">
                      <Skeleton count={6} height={50} />
                    </td>
                  </tr>
                ) : items.length > 0 ? (
                  items.map((item, index) => (
                    <tr
                      key={item?.id}
                      className={`cursor-pointer ${item?.id === activeRow ? "border-l-4 border-secondary bg-gray-50" : "hover:bg-gray-50"}`}
                      onClick={() => setActiveRow(item?.id)}
                    >
                      <td className="max-w-xs py-3 pl-6 pr-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item.name}</p>
                      </td>
                      <td className="py-3 pl-6 pr-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item.campus_count || "0"}</p>
                      </td>
                      <td className="py-3 pl-6 pr-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {moment.unix(item?.created_at_unix).format("D MMM YYYY")}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="h-[500px]">
                    <td colSpan="6">
                      <Lottie options={emptyFolderAnimation} width={200} height={200} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="absolute bottom-4">
            <ReactPaginate
              breakLabel="..."
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              activeClassName="active"
              nextClassName="item next"
              renderOnZeroPageCount={null}
              breakClassName="item break-me "
              containerClassName="pagination"
              previousClassName="item previous"
              pageCount={totalPages}
              pageClassName="item pagination-page"
              forcePage={currentPage - 1}
              onPageChange={handlePageChange}
              nextLabel={<ChevronRightIcon className="h-5 w-5" />}
              previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
            />
          </div>
        </div>
        <div className="card col-span-12 h-[72vh] lg:col-span-5 2xl:col-span-5 3xl:h-[74vh]">
          <div className="mb-6">
            <div className="flex items-center justify-between gap-x-3">
              <Heading heading={detail?.name} />
              <div className="flex shrink-0 gap-x-3 self-start">
                <span
                  className="undreline cursor-pointer text-xs font-normal text-secondary underline underline-offset-4 3xl:text-sm"
                  onClick={() => {
                    setOpenNewDepartmentModal({ open: true, data: detail, editMode: true });
                  }}
                >
                  Edit
                </span>
                <span
                  onClick={() => setRemoveDepartmentModal({ open: true, data: detail?.id, message: null })}
                  className="undreline cursor-pointer text-xs font-normal text-red-500 underline underline-offset-4 3xl:text-sm"
                >
                  Remove Department
                </span>
              </div>
            </div>
          </div>

          <div className="relative -mx-6 mb-8 h-[54vh] overflow-auto 3xl:h-[62vh]">
            <table className="w-full text-left">
              <thead className="sticky top-0">
                <tr>
                  {TABLE_HEAD2.map((head) => (
                    <th key={head} className="border-b border-gray-100 bg-white p-4 first:pl-6">
                      <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">{head}</p>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td colSpan="3" className="w-full p-4">
                    <div className="grid w-full grid-cols-12 items-center gap-x-4">
                      <div
                        className={`${selectedItems?.length > 0 ? "col-span-9 xl:mr-12 2xl:mr-4  " : "col-span-12 "}cursor-pointer rounded-full border px-3 py-2 text-sm font-normal text-primary-color-200`}
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <div className="-mb-0.5  flex flex-nowrap overflow-x-auto  ">
                          {selectedItems.length > 0 ? (
                            selectedItems.map((item, index) => (
                              <div key={index} className="m-1 flex shrink-0 rounded-full bg-secondary/5 py-1 px-3 text-secondary">
                                {item.label}
                                <button onClick={() => handleRemove(item)} className="ml-3 flex items-center">
                                  <XMarkIcon className="h-4 w-4 cursor-pointer text-primary-color-200" />
                                </button>
                              </div>
                            ))
                          ) : (
                            <>
                              <div className="flex items-center">
                                <img src={images.Campus_Icon} alt="department_icon" />
                                <p className="py-2 px-2 font-normal text-primary-color-100">Add New Site</p>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {selectedItems?.length > 0 && (
                        <div className="col-span-3">
                          <div className="flex items-center justify-end gap-x-2 ">
                            <Button btnSmall title="Add" onClick={() => addCampus(detail)} />
                            <h3
                              onClick={() => setSelectedItems([])}
                              className="flex h-[38px] cursor-pointer items-center justify-center rounded-full border border-secondary px-4 text-sm  font-medium text-secondary hover:shadow-lg"
                            >
                              Cancel
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="relative" ref={dropdownRef}>
                      {dropdownOpen && (
                        <div className="absolute z-10 mt-3 w-full rounded-md bg-white p-5 shadow-lg">
                          <div className="h-36 overflow-y-scroll">
                            {items3?.length > 0 ? (
                              items3?.map((item, index) => (
                                <div key={index} onClick={() => handleSelect(item)} className="cursor-pointer p-1 text-primary-color-100">
                                  {item.label}
                                </div>
                              ))
                            ) : (
                              <div className="flex h-full w-full items-center justify-center">
                                <p className="text-center text-primary-color-100 ">There is nothing to show</p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                {loading ? (
                  <tr>
                    <td colSpan="6">
                      <Skeleton count={6} height={50} />
                    </td>
                  </tr>
                ) : detail?.campus?.length > 0 ? (
                  detail?.campus?.map((item, index) => (
                    <tr key={item?.id} className="even:bg-gray-50">
                      <td className="py-3 pl-6 pr-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{index + 1}</p>
                      </td>
                      <td className="py-3 pl-6 pr-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item.name}</p>
                      </td>
                      <td className="w-[12rem] py-3 pl-4 pr-3 3xl:px-4">
                        <div className="flex gap-x-3">
                          <span className=" cursor-pointer text-xs font-normal text-red-500 ">
                            {activeItemIndex !== index && <img src={images.Delete_Icon} alt="delete_icon" onClick={() => handleDeleteClick(index)} />}
                            {activeItemIndex === index && (
                              <div>
                                <div className="flex items-center justify-end gap-x-2">
                                  <h3
                                    onClick={() => {
                                      // removeCampus(item);
                                      // handleCancelClick();
                                      setRemoveCampusDepartment({ open: true, data: item, message: null });
                                    }}
                                    className="flex h-[22px] cursor-pointer items-center justify-center rounded-full border bg-red-500 px-2 text-xs text-white hover:shadow-lg"
                                  >
                                    Remove
                                  </h3>
                                  <h3
                                    onClick={handleCancelClick}
                                    className="flex h-[22px] cursor-pointer items-center justify-center rounded-full border border-secondary px-2 text-xs text-secondary hover:shadow-lg"
                                  >
                                    Cancel
                                  </h3>
                                </div>
                              </div>
                            )}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="h-[200px] ">
                    <td colSpan="6">
                      <Lottie options={emptyFolderAnimation} width={200} height={200} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AddDepartment
        isOpen={openNewDepartmentModal.open}
        setIsOpen={(open) => setOpenNewDepartmentModal((prev) => ({ ...prev, open }))}
        editMode={openNewDepartmentModal.editMode}
        data={openNewDepartmentModal.data}
        api={getDepartmentList}
        setSelectedDepartmentIndex={setSelectedDepartmentIndex}
      />

      <ConfirmationModal
        label="Remove Site"
        error={removeCampusDepartment.message}
        isOpen={removeCampusDepartment.open}
        setIsOpen={(open) => setRemoveCampusDepartment((prev) => ({ ...prev, open }))}
        handleSubmit={removeCampus}
      />
      <ConfirmationModal
        label="Remove Department"
        innerLabel="Deleting the department will remove the department from connected employees as well."
        error={removeDepartmentModal.message}
        isOpen={removeDepartmentModal.open}
        setIsOpen={(open) => setRemoveDepartmentModal((prev) => ({ ...prev, open }))}
        handleSubmit={removeDepartment}
      />
    </>
  );
};

export default Department;
